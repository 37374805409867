
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';

@Component
export default class ProgressBar extends Vue {
    @Prop({ default: 0 }) private value!: number;

    @Prop({
        default: ProgressBar.PROGRESS_BAR_TYPE.horizontal,
        validator: (val: string) => ProgressBar.PROGRESS_BAR_TYPE[val] !== undefined,
    }) private type!: string;

    static readonly PROGRESS_BAR_TYPE = {
        horizontal: 'horizontal',
        vertical: 'vertical',
    }

    get elementClasses() {
        return [
            'ProgressBar__Background  ProgressBar__Container',
            `ProgressBar--${this.type}`,
        ];
    }

    get styleObject() {
        if (this.type === ProgressBar.PROGRESS_BAR_TYPE.horizontal) {
            return {
                width: `${this.value}%`,
            };
        }
        return { height: `${this.value}%` };
    }
}
