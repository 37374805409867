
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';

@Component
export default class ChartSales extends Vue {
    @Prop({ required: true }) private bookedEvents!: number;

    @Get(dashboard, 'data.performanceData.avgSalesPerEvent') private avgSalesPerEvent!: number;

    @Get(dashboard, 'data.performanceData.totalSales') private totalSales!: number;

    get projectedSales(): number {
        const allEventsSales = this.avgSalesPerEvent * this.bookedEvents;
        const totalSales = this.totalSales + allEventsSales;
        return Math.round(totalSales * 100) / 100;
    }

    format2DecimalPoints(val) {
        return Number(val).toFixed(2);
    }
}
