import dateManager from '@/utils/time';
import { ResolverEnrolleeType } from '../types';
import { enrollee } from './enrollee';
import { ranks } from './ranks';

const sortNewStartersData = (newStartersData: ResolverEnrolleeType[]) => {
    const inSSPeriod: ResolverEnrolleeType[] = newStartersData
        .filter((starter: ResolverEnrolleeType) => {
            const { joinDate, kitPurchaseDate } = starter;

            return dateManager
                .isAfter(
                    `${dateManager.getDateTime(kitPurchaseDate || joinDate, dateManager.getDateFormat())} 00:00`,
                    `${dateManager.getCurrentDateWithOffset(-29, dateManager.getDateFormat())} 00:00`,
                );
        }).sort((a, b) => (dateManager
            .isBefore(
                b.kitPurchaseDate || b.joinDate,
                a.kitPurchaseDate || a.joinDate,
            ) ? 1 : -1));

    const afterSSPeriod: ResolverEnrolleeType[] = newStartersData
        .filter((starter: ResolverEnrolleeType) => {
            const { joinDate, kitPurchaseDate } = starter;

            return dateManager
                .isBefore(
                    `${dateManager.getDateTime(kitPurchaseDate || joinDate, dateManager.getDateFormat())} 00:00`,
                    dateManager.getCurrentDateWithOffset(-28, dateManager.getDateFormat()),
                );
        }).sort((a, b) => (dateManager
            .isBefore(
                a.kitPurchaseDate || a.joinDate,
                b.kitPurchaseDate || b.joinDate,
            ) ? 1 : -1));

    return [...inSSPeriod, ...afterSSPeriod];
};

const newStarters: (enrolleeId: string, params: { date: string, type: string,
     limit?: number, offset?: number})
 => ResolverEnrolleeType[] = (enrolleeId, params) => {
     const { limit, offset } = params;

     const enrolleeItem: ResolverEnrolleeType = enrollee(enrolleeId);

     const res = enrolleeItem.directDownline.map((element) => enrollee(element))
         .filter((element) => dateManager
             .isAfter(element.kitPurchaseDate, params.date));
     if (params.type === 'personal') {
         if (limit !== undefined && offset !== undefined) {
             return sortNewStartersData(res).splice(offset, limit);
         }
         return sortNewStartersData(res);
     }

     if (params.type === 'central_team') {
         const taRank = ranks.find((rank) => rank.label === 'temporary.rank.teamLeader');
         let centralTeam: ResolverEnrolleeType[] = [...res];

         enrolleeItem.directDownline.map((element) => enrollee(element))
             .filter((element) => {
                 const elementRank = ranks.find((rankElement) => rankElement.id === element.rankId);
                 return elementRank!.power < taRank!.power;
             })
             .forEach((element) => {
                 element.directDownline.forEach((directItem) => {
                     const resItem = newStarters(directItem,
                         { date: params.date, type: params.type });
                     centralTeam = [...centralTeam, enrollee(directItem), ...resItem];
                 });
             });

         const result = sortNewStartersData(centralTeam
             .filter((element) => dateManager.isAfter(element.kitPurchaseDate, params.date)));

         if (limit !== undefined && offset !== undefined) {
             return result.splice(offset, limit);
         }

         return result;
     }

     let downlineRes:ResolverEnrolleeType[] = [];

     res.forEach((element) => {
         element.directDownline.forEach((directItem) => {
             const resItem = newStarters(directItem, { date: params.date, type: params.type });
             downlineRes = [...downlineRes, enrollee(directItem), ...resItem]
                 .filter((resItemElement) => dateManager
                     .isAfter(resItemElement.kitPurchaseDate, params.date));
         });
     });

     const result = sortNewStartersData([...res, ...downlineRes]);
     if (limit !== undefined && offset !== undefined) {
         return result.splice(offset, limit);
     }

     return result;
 };

export {
    newStarters,
};
