var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"Navigation__Container"},[_c('div',{staticClass:"Navigation__LogoSection"},[_c('div',{staticClass:"Navigation__Logo"},[(_vm.screenType !== 'tablet')?_c('img',{attrs:{"src":`${_vm.baseUrl}img/logo.svg`,"alt":"Plum Tree Systems Logo"}}):_c('img',{attrs:{"src":`${_vm.baseUrl}img/pts-logo-landscape-white.png`,"alt":"Plum Tree Systems Logo"}})]),_c('span',[_vm._v(" "+_vm._s(`${_vm.impersonating ? _vm.$t('view.navigation.impersonating') : "" }${_vm.firstName} ${_vm.lastName}`)+" ")])]),(_vm.isAmbassador)?_c('Section',{attrs:{"title":_vm.$t('view.navigation.section.home')}},[_c('NavItem',{attrs:{"title":_vm.$t('view.navigation.item.dashboard'),"icon":"dashboard","route":{
                name: 'dashboard',
            }}})],1):_vm._e(),(_vm.isAmbassador)?_c('Section',{attrs:{"title":_vm.$t('view.navigation.section.downline')}},[_c('NavItem',{attrs:{"title":_vm.$t('view.navigation.item.newEnrollees'),"icon":"fiber_new","route":{
                name: 'newPartners',
            }}}),_c('NavItem',{attrs:{"title":_vm.$t('view.navigation.item.team'),"icon":"star_outline","route":{
                name: 'myTeam',
            }}}),_c('NavItem',{attrs:{"title":_vm.$t('view.navigation.item.leaders'),"icon":"star_half","route":{
                name: 'myLeaders',
            }}}),_c('NavItem',{attrs:{"title":_vm.$t('view.navigation.item.businessShape'),"icon":"square_foot","route":{
                name: 'myBusinessShape',
            }}}),_c('NavItem',{attrs:{"title":_vm.$t('view.navigation.item.downline'),"icon":"account_tree","route":{
                name: 'myDownline',
            }}})],1):_vm._e(),(!_vm.impersonating && _vm.isAmbassador)?_c('Section',{attrs:{"title":_vm.$t('view.navigation.section.earnings')}},[_c('NavItem',{attrs:{"title":_vm.$t('view.navigation.item.commissions'),"icon":"description","route":{
                name: 'incomeSummary',
            }}}),_c('NavItem',{attrs:{"title":_vm.$t('view.navigation.item.reports'),"icon":"article","route":{
                name: 'reports',
            }}}),_c('NavItem',{attrs:{"title":_vm.$t('view.navigation.item.storeCredit'),"icon":"receipt","route":{
                name: 'storeCredit',
            },"disabled":!_vm.unlockedAfterStage(1)}})],1):_vm._e(),(_vm.isAmbassador)?_c('Section',{attrs:{"title":_vm.$t('view.navigation.section.orders')}},[_c('NavItem',{attrs:{"title":_vm.$t('view.navigation.item.orders'),"icon":"shopping_cart","route":{
                name: 'orders',
            },"disabled":!_vm.unlockedAfterStage(1)}})],1):_vm._e(),_c('Section',{attrs:{"title":_vm.$t('view.navigation.section.events')}},[(!_vm.impersonating && _vm.isAmbassador)?_c('NavItem',{attrs:{"title":_vm.$t('view.navigation.item.createEvent'),"icon":"event","route":{
                name: 'createEvent',
            }}}):_vm._e(),_c('NavItem',{attrs:{"title":_vm.$t('view.navigation.item.events'),"icon":"date_range","route":{
                name: 'eventCalendar',
            }}})],1),(_vm.isAmbassador || (!_vm.isAmbassador && _vm.isDevelopmentEnvironment))?_c('Section',{attrs:{"title":_vm.$t('view.navigation.section.audience')}},[(_vm.isAmbassador)?_c('NavItem',{attrs:{"title":_vm.$t('view.navigation.item.shareLink'),"icon":"person_add","route":{
                name: 'invite',
            }}}):_vm._e(),(_vm.isAmbassador)?_c('NavItem',{attrs:{"title":_vm.$t('view.navigation.item.customers'),"icon":"group","route":{
                name: 'myCustomers',
            }}}):_vm._e(),(_vm.isDevelopmentEnvironment)?_c('NavItem',{attrs:{"title":"*** UI Components","route":{
                name: 'uiComponents',
            }}}):_vm._e(),(_vm.impersonating)?_c('NavItem',{attrs:{"title":_vm.$t('view.navigation.item.endImpersonating'),"icon":"exit_to_app","route":{
                name: 'endImpersonation',
            }}}):_vm._e()],1):_vm._e(),(!_vm.impersonating)?_c('Section',{attrs:{"title":_vm.$t('view.navigation.section.account')}},[_c('NavItem',{attrs:{"title":_vm.$t('view.navigation.item.profile'),"icon":"account_circle","route":{
                name: 'profile',
            }}}),_c('NavItem',{attrs:{"title":_vm.$t('view.navigation.item.logout'),"icon":"exit_to_app","route":{
                name: 'logout',
            }}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }