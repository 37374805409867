import API from '@/api/graphQL';
import { ProfileParamsType } from '@/api/graphQL/graphNodes/GetProfileQuery';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import RestAPI from '@/api/rest';

export default class ProfileRepository {
    static getProfile(params: ProfileParamsType) {
        return API
            .getProfile()
            .query(params, {}, { type: '' });
    }

    static updateProfile(profile) {
        return API
            .updateProfile()
            .query({ profile });
    }

    static uploadProfileImage(data) {
        return API
            .uploadProfilePicture()
            .query(data, { formData: true });
    }

    static getCountries() {
        return API
            .getCountries()
            .query();
    }
}
