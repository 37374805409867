// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { HostType } from './types';

export default class GetHostQuery extends
    AbstractQueryResource<GetHostResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($id: String) {
            enrollee(id: $id) {
                id,
                firstName,
                lastName,
                phoneNumber,
                email,
                address,
                secondAddress,
                birthDate,
                postCode,
                town,
                county,
                region,
                country,
                parentId,
                rank {
                    id,
                    label,
                },
                hidden
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getHost';
    }
}

export type GetHostResultType = {
    enrollee: HostType;
}

export type GetHostParamsType = {
    id: string;
}
