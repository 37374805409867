// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetUnderInspectionQuery extends
    AbstractQueryResource<GetUnderInspectionResultType> {
    protected getQuery(): string {
        return `query ${this.getName()} {
            profile {
                region,
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getUnderInspection';
    }
}

export type GetUnderInspectionResultType = {
    profile: {
        region: string;
    };
}
