import API from '@/api/graphQL';
import { GENEALOGY_ENROLLEE_PARENT_TYPES, GetGenealogyEnrolleeParamsType } from '@/api/graphQL/graphNodes/GetGenealogyEnrolleeQuery';
import { LeaderLegsParamsType, LeaderLegsSearchParamsType } from '@/api/graphQL/graphNodes/GetLeaderLegsQuery';
import { GetLeaderLegsByIdQueryParamsType } from '@/api/graphQL/graphNodes/GetLeaderLegsByIdQuery';

export default class LeaderLegsRepository {
    static getLeaderLegs(params: LeaderLegsParamsType, type: string = '') {
        return API
            .getLeaderLegs()
            .query(params, {}, { type });
    }

    static searchLeaderLegs(params: LeaderLegsSearchParamsType) {
        return API
            .getLeaderLegs()
            .query(params, {}, { type: 'search' });
    }

    static getLeaderLegsById(params: GetLeaderLegsByIdQueryParamsType) {
        return API
            .getLeaderLegsById()
            .query(params);
    }

    static getLeg(params: GetGenealogyEnrolleeParamsType) {
        return API
            .getGenealogyEnrollee()
            .query(params, {}, {
                withParent: true,
                parentType: GENEALOGY_ENROLLEE_PARENT_TYPES.identity,
            });
    }
}
