

import { Component, Vue } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import calendar from '@/modules/Calendar';
import { CALENDAR_TIME_FRAME_OPTIONS } from '@/modules/Calendar/constants';
import TimeControls from './Controls/index.vue';

@Component({
    components: {
        TimeControls,
    },
})
export default class Time extends Vue {
    @Get(calendar, 'calendarDate') date!: string;

    @Get(calendar, 'calendarTimeFrame') calendarTimeFrame!: string;

    get isDailyView() {
        return this.calendarTimeFrame === CALENDAR_TIME_FRAME_OPTIONS.day;
    }

    get selectedMonthDate() {
        const splitted = calendar.getSelectedMonthDate.split(' ');
        const month = this.$t(`ui.time.long.month.${splitted[0].toLowerCase().slice(0, 3)}`);

        return `${month} ${splitted[1]}`;
    }

    get selectedDayDate() {
        const splitted = calendar.getSelectedDayDisplayDate.split(' ');
        const month = this.$t(`ui.time.short.day.${splitted[0].toLowerCase().slice(0, 3)}`);

        return `${month} ${splitted[1]}`;
    }
}
