
import { Vue, Component, Prop } from 'vue-property-decorator';
import { DownlineExplorerType } from '@/api/graphQL/graphNodes/types';
import CurrencyPrefix from '@/utils/currencyPrefix';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';

@Component
export default class DownlineCurrentPerformance extends Vue {
    @Prop() private leg!: DownlineExplorerType;

    get currencyPrefix() {
        return CurrencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }
}
