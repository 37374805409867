// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { IncomeSummaryType } from './types';

export default class GetIncomeSummaryQuery extends
    AbstractQueryResource<GetIncomeSummaryResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($dateFrom: DateTime!, $dateTo: DateTime!) { 
            profile {
                incomeSummary(from: $dateFrom, to: $dateTo) {
                    pv,
                    gv,
                    ov,
                    title {
                        label
                    },
                    total,
                    bonusTotals {
                        label,
                        key,
                        amount,
                    },
                },
                id,
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getIncomeSummary';
    }
}

export type GetIncomeSummaryResultType = {
    profile: {
        incomeSummary: IncomeSummaryType;
    }
}

export type GetIncomeSummaryParamsType = {
    dateFrom: string;
    dateTo: string;
}
