
import { Vue, Component, Prop } from 'vue-property-decorator';
import { AssetType } from '@/api/graphQL/graphNodes/types';
import { assetFileResolver, getType } from '@/projectComponents/fileViewer/service/fileResolvers';
import env from '@/environment';
import VideoViewer from './VideoViewer/index.vue';
import PdfViewer from './PdfViewer/index.vue';
import VideoFileViewer from './VideoFileViewer/index.vue';
import ImageViewer from './ImageViewer/index.vue';

@Component({
    components: {
        VideoViewer,
        PdfViewer,
        VideoFileViewer,
        ImageViewer,
    },
})
export default class FileViewer extends Vue {
    @Prop({ required: true }) private file!: AssetType;

    @Prop() private token!: string;

    get src() {
        if (this.type === 'video' || this.type === 'externalFile') {
            const metric = this.file.context.find((item) => item.metric === 'Embed-Link');

            if (metric) {
                return metric.value;
            }

            return '';
        }

        const token = this.token ? `?token=${this.token}` : '';
        return `${this.serverUrl}${this.file.link}${token}`;
    }

    get serverUrl(): string {
        return env.VUE_APP_SERVER_URL;
    }

    get elementClasses() {
        return [
            'FileViewer__Container',
        ];
    }

    get type(): string {
        if (!this.file) {
            return '';
        }

        const type = getType(this.file);

        if (type) {
            return assetFileResolver(type.value);
        }

        return '';
    }

    handleLoaded() {
        this.$emit('loaded');
    }
}
