export default {
    translation: {
        temporary: {
            type: {
                direct: 'direkt',
            },
            rank: {
                customer: 'Partner',
                teamLeader: 'Teamleiter',
                groupLeader: 'Gruppenleiter',
                divisionalLeader: 'Bereichsleiter',
                executiveLeader: 'Geschäftsführer',
            },
            bonusTotals: {
                centralTeam: 'Zentrale Team Bonus',
                leadership1: 'Führung 1',
                leadership2: 'Führung 2',
                leadership3: 'Führung 3',
                leadership4: 'Führung 4',
                additionalSalesBonus: 'Zusätzlicher Verkaufsbonus',
                productivityBonus: 'Produktivitätsbonus',
                adjustments: 'Anpassungen',
            },
            achievementAwards: {
                topPartnerParty: {
                    tooltip: 'Jeder Partner, jedes Startdatum, basierend auf persönlichen Verkäufen nur aus Party-Bestellarten',
                    title: 'Top-Partner (Party)',
                    description: 'Persönliche Verkäufe aus Partybestellungen',
                },
                topNewPartnerParty: {
                    tooltip: 'Partner, der in diesem Jahr nur aufgrund persönlicher Verkäufe aus Partybestellarten beigetreten ist',
                    title: 'Top-Partner (Party)',
                    description: 'Persönliche Verkäufe aus Partybestellungen',
                },
                topPartnerSchools: {
                    tooltip: 'Jeder Partner, jedes Startdatum, basierend auf persönlichen Verkäufen nur aus Bestellarten',
                    title: 'Top-Partner',
                    description: 'Persönlicher Umsatz aus Bestellungen',
                },
                topNewPartnerSchools: {
                    tooltip: 'Partner, der in diesem Jahr beigetreten ist, ausschließlich aufgrund persönlicher Verkäufe aus Bestellarten',
                    title: 'Top neuer Partner',
                    description: 'Persönlicher Umsatz aus Neuaufträgen',
                },
                topLeader: {
                    tooltip: 'Jede Leitungsposition, jeder Starttermin basierend auf den persönlichen Teamumsätzen',
                    title: 'Spitzenreiter',
                    description: 'Neue Rekrutenverkäufe',
                },
                topNewLeader: {
                    tooltip: 'Partner, die in diesem Jahr aufgrund persönlicher Teamverkäufe beigetreten sind',
                    title: 'Top neuer Anführer',
                    description: 'Neue Rekrutenverkäufe',
                },
                topRecruiter: {
                    tooltip: 'Jeder Partner, jedes Startdatum basierend auf den durch QuickStart-Erfolge von persönlichen Rekruten verdienten Punkten',
                    title: 'Top-Recruiter',
                    description: 'Team QuickStart-Erfolge',
                },
                topNewRecruiter: {
                    tooltip: 'Partner, der in diesem Jahr aufgrund der durch QuickStart-Erfolge von persönlichen Rekruten gesammelten Punkte beigetreten ist',
                    title: 'Top neuer Personalvermittler',
                    description: 'Team QuickStart-Erfolge',
                },
                topTravelSeller: {
                    tooltip: 'Top-Verkäufer von Reise-Incentives',
                    title: 'Top-Verkäufer von Reise-Incentives',
                    description: 'Top-Verkäufer von Reise-Incentives',
                },
                topTravelRecruiter: {
                    tooltip: 'Top-Recruiter für Reise-Incentives',
                    title: 'Top-Recruiter für Reise-Incentives',
                    description: 'Top-Recruiter für Reise-Incentives',
                },
                topTravelPromoting: {
                    tooltip: 'Top Reisewerbung',
                    title: 'Top Reisewerbung',
                    description: 'Top Reisewerbung',
                },
                topTravelLeader: {
                    tooltip: 'Top-Reise-Incentive-Anbieter',
                    title: 'Top-Reise-Incentive-Anbieter',
                    description: 'Top-Reise-Incentive-Anbieter',
                },
                topTravelOverall: {
                    tooltip: 'TBC',
                    title: 'Top-Reisen insgesamt',
                    description: 'Top-Reisen insgesamt',
                },
            },
            trainingAssetCategories: {
                superStarterAcademy: 'Superstarter Akademie',
                productExpert: 'Produktexperte',
                socialMediaSuccess: 'Erfolg in sozialen Medien',
                partyAndEvents: 'Party und Events',
            },
            marketingAssetCategories: {
                catalogs: 'Kataloge',
                promoVideos: 'Werbevideos',
                promotionMaterial: 'Werbematerial',
            },
            salePayment: 'SPEICHERKREDIT',
        },
        ui: {
            money: '€',
            save: 'speichern',
            submit: 'einreichen',
            close: 'schließen',
            preview: 'vorschau',
            download: 'herunterladen',
            comingSoon: 'kommt bald',
            create: 'erstellen',
            remove: 'entfernen',
            optional: 'freiwillig',
            send: 'schicken',
            select: 'wählen',
            current: 'Aktuell',
            required: 'Erforderlich',
            explore: 'Erkunden',
            viewAs: 'Anzeigen Als',
            reupload: 'Erneut Hochladen',
            search: 'Suchen',
            edit: 'Bearbeiten',
            backToApp: 'zurück zur App',
            login: 'Login',
            goBack: 'geh zurück',
            resend: 'erneut senden',
            time: {
                label: 'zeit',
                timeZone: 'Zeitzone',
                short: {
                    day: {
                        mon: 'Mo',
                        tue: 'Di',
                        wed: 'Mi',
                        thu: 'Do',
                        fri: 'Fr',
                        sat: 'Sa',
                        sun: 'So',
                    },
                    month: {
                        jan: 'jan',
                        feb: 'feb',
                        mar: 'mar',
                        apr: 'apr',
                        may: 'mai',
                        jun: 'jun',
                        jul: 'jul',
                        aug: 'aug',
                        sep: 'sep',
                        oct: 'okt',
                        nov: 'nov',
                        dec: 'dez',
                    },
                },
                long: {
                    day: {
                        mon: 'Montag',
                        tue: 'Dienstag',
                        wed: 'Mittwoch',
                        thu: 'Donnerstag',
                        fri: 'Freitag',
                        sat: 'Samstag',
                        sun: 'Sonntag',
                    },
                    month: {
                        jan: 'Januar',
                        feb: 'Februar',
                        mar: 'Marsch',
                        apr: 'April',
                        may: 'Mai',
                        jun: 'Juni',
                        jul: 'Juli',
                        aug: 'August',
                        sep: 'September',
                        oct: 'Oktober',
                        nov: 'November',
                        dec: 'Dezember',
                    },
                },
            },
            navigation: {
                open: 'Navigation öffnen',
                close: 'Navigation schließen',
            },
            fileArea: {
                addDocument: 'Dokument hinzufügen',
                placeholderTitle: 'Dateien hier ablegen',
                placeholderSubtitle: 'Oder verwenden Sie die Schaltfläche "Hinzufügen"',
            },
            imageCrop: {
                alt: 'Quellbild',
            },
            searchOptions: {
                noOptions: 'Keine Optionen gefunden',
            },
        },
        message: {
            invitationSent: 'Einladung erfolgreich gesendet',
            invitationNotSent: 'Die Einladung wurde nicht gesendet',
            over18Message: 'Sie müssen über 18 Jahre alt sein, um ein unabhängiger Partner zu werden',
            fieldPartOfKYC: 'Dieses Feld ist Teil unseres KYC-Prozesses',
            registerSuccess: 'Erfolgreich registriert',
            invitationResent: 'Die Email wurde erneut an Ihren Posteingang gesendet.',
            updatedSuccessfully: 'Erfolgreich aktualisiert',
            network: {
                connectionReestablished: 'Ihre Netzwerkverbindung wurde wiederhergestellt',
                networkError: 'Anscheinend haben Sie Probleme mit der Netzwerkverbindung',
            },
            file: {
                fileDownloading: 'Herunterladen {{fileName}}',
            },
        },
        tooltip: {
            firstName: 'Ihr Vorname, wie er in offiziellen Dokumenten erscheint. Bitte verwenden Sie keine Abkürzungen oder Spitznamen.',
            lastName: 'Ihr Nachname, wie er in offiziellen Dokumenten erscheint.',
            birthDate: 'Sie müssen über 18 Jahre alt sein, um ein unabhängiger Partner zu werden.',
            nameOfAccount: 'Damit wir Provisionen direkt und sicher auf Ihr Konto auszahlen können, erfragen wir Ihre Bankverbindung.',
            shippingAddress: 'Den Adressnamen sehen Kunden in ihrer Veranstaltungsbestellung. Die tatsächliche Lieferadresse geben wir nicht öffentlich bekannt.',
        },
        areaLabel: {
            goBack: '$t(ui.goBack)',
            progressOf: 'Fortschritt von {{val}}',
            requirementFulfilled: 'Anforderung erfüllt',
            requirementUnfulfilled: 'Anforderung unerfüllt',
            facebook: 'Über Facebook teilen',
            twitter: 'Über Twitter teilen',
            whatsApp: 'Über WhatsApp teilen',
            scrollToTop: 'Scrollen Sie nach oben',
            rankProgressTab: 'Registerkarten „Rangfortschritt“',
            info: 'Info',
            clipboard: {
                copyLink: 'Link kopieren',
            },
            datePicker: {
                previousMonth: 'Vorheriger Monat',
                nextMonth: 'Nächsten Monat',
            },
            expansionItem: {
                expandLess: 'Erweitern Sie weniger',
                expandMore: 'Erweitern Sie mehr',
            },
            pagination: {
                firstPage: 'Erste seite',
                previousPage: 'Vorherige seite',
                nextPage: 'Nächste seite',
                lastPage: 'Letzte seite',
            },
        },
        validation: {
            yearFormat: 'Gültiges Jahresformat ist 19XX und 20XX',
            invalidOptionSelected: 'Ungültige Option ausgewählt',
            invalidForm: 'Das Formular ist ungültig',
            requiredField: 'Dieses Feld ist erforderlich',
            invalidEmail: 'Ungültige E-Mail adresse',
            eventCanceled: 'Die Veranstaltung wurde erfolgreich abgesagt',
            eventDescriptionMaxLength: 'Die Beschreibung ist zu lang',
            eventMinimumTimeFrame: 'Der Zeitraum ist zu kurz. Das Minimum beträgt 1 Stunde.',
            emptyAddressAndVideoUrl: 'Das Party muss entweder eine Adresse oder eine Videokonferenz-URL enthalten',
            businessDetails: 'Die Felder Firmenname und Konto müssen beide ausgefüllt sein oder keines',
            driverLicenseFormat: 'Der Führerschein muss ein gültiges Format haben',
            passwordMatch: 'Bitte stellen Sie sicher, dass Ihre Passwörter übereinstimmen',
            age18: 'Wir verlangen, dass Benutzer mindestens 18 Jahre alt sind',
            passportMrz: 'Die MRZ-Nummer des Reisepasses muss ein gültiges Format haben',
            incompleteRequiredFields: 'Es gibt unvollständige Pflichtfelder',
        },
        common: {
            year: 'Jahr',
            month: 'Monat',
            week: 'Woche',
            day: 'Tag',
            dayLower_one: 'tag',
            dayLower_other: 'tage',
            dayLeftLower_one: '1 tag übrig',
            dayLeftLower_other: 'Noch {{ count }} tage',
            date: 'Datum',
            done: 'Erledigt',
            details: 'Details',
            notDone: 'Nicht erledigt',
            all: 'Alle',
            na: 'N/A',
            firstName: 'Vorname',
            lastName: 'Nachname',
            name: 'Name',
            email: 'E-Mail',
            info: 'Info',
            id: 'Ausweis',
            phone: 'Telefon',
            phone2: 'Telefon 2',
            phoneNumber: 'Telefonnummer',
            address: 'Adresszeile',
            address2: 'Adresszeile 2',
            currentAddress: 'Aktuelle Adresse',
            joinDate: 'Beitrittsdatum',
            performance: 'Leistung',
            ps: 'Persönlicher Verkauf',
            gv: 'Persönlicher Teamverkauf',
            ov: 'Titelverkäufe insgesamt',
            teamSales: 'Team Vertrieb',
            mentor: 'Mentorin',
            enrollee: 'Partner',
            host: 'Gastgeber',
            passport: 'Reisepass',
            currentRank: 'Aktueller rang',
            nextRank: 'Nächster rang',
            register: 'Registrieren',
            agree: 'Zustimmen',
            accept: 'Akzeptieren',
            accepted: 'Akzeptiert',
            decline: 'Ablehnen',
            declined: 'Abgelehnt',
            tentative: 'Vorläufig',
            setPassword: 'Passwort festlegen',
            repeatPassword: 'Passwort wiederholen',
            country: 'Land',
            tnc: 'Geschäftsbedingungen',
            invitation: 'Einladung',
            invite: 'Einladen',
            invitationLink: 'Einladungslink',
            facebook: 'Facebook',
            twitter: 'Twitter',
            whatsApp: 'WhatsApp',
            description: 'Beschreibung',
            quantity: 'Menge',
            price: 'Preis',
            salesCommission: 'Verkaufsprovision',
            psVolume: 'PS Volumen',
            total: 'Gesamt',
            orderNumber: 'Bestellnummer',
            originalOrderNumber: 'Ursprüngliche Bestellnummer',
            reason: 'Grund',
            totalOrderValue: 'Gesamtbestellwert',
            deliveryCharge: 'Versandkosten',
            creditUsed: 'Guthaben verwendet',
            birthDate: 'Geburtsdatum',
            shareViaQRCode: 'teilen per QR-Code',
            inviteGuestsViaQR: 'Laden Sie Gäste per QR-Code ein',
            scanWithCamera: 'Scannen Sie dies einfach mit der Kamera Ihres Mobiltelefons',
            accessDenied: 'Zugriff verweigert',
            fileNotFound: 'Datei wurde nicht gefunden',
            noFiles: 'Derzeit sind keine Dateien vorhanden',
            signIn: 'Anmelden',
            type: 'Typ',
            orders: 'Bestellungen',
            salesInWeek_one: 'Verkauf in 1 Woche',
            salesInWeek_other: 'Verkäufe in {{ count }} Wochen',
            products: 'Produkte',
            placeholder: 'Platzhalter',
            logout: 'Abmelden',
            null: 'null',
            location: 'Standort',
            loadMore: 'Mehr laden',
            monthly: 'Monatlich',
            weekly: 'Wöchentlich',
            daily: 'Täglich',
            personal: 'Persönlich',
            downline: 'Downline',
            today: 'Heute',
            period: 'Zeitraum',
            documentType: 'Dokumenttyp',
            drivingLicense: 'Führerschein',
            sortType: 'Sortiertyp',
            supportEmail: 'info@pts.com',
            canceled: 'Abgesagt',
            closed: 'Geschlossen',
            title: 'Titel',
            event: 'Party',
            customer: 'Kundin',
            orderValue: 'Bestellwert',
            commissionable: 'Provisionsfähig',
            totals: 'Summen',
            to: 'zu',
            me: 'mich',
            meUpper: 'Mich',
            comingSoon: 'Die Funktion wird zu einem späteren Zeitpunkt verfügbar sein.',
            signUp: 'Melden Sie sich an',
            shop: 'Geschäft',
            short: {
                ps: 'PS',
            },
            youCannotAccessThisPage: 'Sie können nicht auf diese Seite zugreifen',
            termsAndConditions: 'Allgemeine Geschäftsbedingungen',
            bankingDetails: {
                accountName: 'Kontoname',
                gb: {
                    bankSortCode: 'Kontoleitzahl',
                    bankAccountNumber: 'Kontonummer',
                },
                ie: {
                    bankSortCode: 'SWIFT-Code der Bank',
                    bankAccountNumber: 'IBAN-Nummer der Bank',
                },
            },
            management: 'Management',
            calendars: 'Kalender',
            registration: {
                steps: {
                    stepOne: 'Melden Sie sich an',
                    stepTwo: 'Bestellen Sie Ihr Kit',
                    stepThree: 'Sie sind bereit!',
                },
            },
            countries: {
                france: {
                    shortTitle: 'FR',
                    title: 'Frankreich',
                },
                germany: {
                    shortTitle: 'DE',
                    title: 'Deutschland',
                },
                italy: {
                    shortTitle: 'IT',
                    title: 'Italien',
                },
                spain: {
                    shortTitle: 'ESP',
                    title: 'Spanien',
                },
                poland: {
                    shortTitle: 'PL',
                    title: 'Polen',
                },
                romania: {
                    shortTitle: 'RO',
                    title: 'Rumänien',
                },
                slovakia: {
                    shortTitle: 'SK',
                    title: 'Slowakei',
                },
                greatBritain: {
                    shortTitle: 'GB',
                    title: 'Vereinigtes Königreich',
                },
                unitedKingdom: {
                    shortTitle: 'UK',
                    title: 'Vereinigtes Königreich',
                },
            },
        },
        view: {
            appInstall: {
                content1: 'Als App hinzufügen und außerhalb von {{ browserName }}.',
                content2: 'So installieren Sie diese App: Tippen Sie auf',
                content3: 'symbol und tippen Sie anschließend auf „Zum Homescreen hinzufügen“',
            },
            auth: {
                returnButton: 'Zurück zur Anmeldung',
                tenant: 'Unternehmen <b>{{ authFailMessage }}</b> ist derzeit nicht erreichbar. Für weitere Informationen kontaktieren Sie die Eigentümer unter einer der folgenden E-Mails: <br>{{wrappedContacts}}.',
                accountDisabled: 'Ihr Konto hat keinen Zugriff auf die Firma <b>{{ authFailMessage }}</b>. Weitere Informationen erhalten Sie von einem Administrator unter einer der folgenden E-Mail-Adressen: <br>{{wrappedContacts}}.',
                signingOut: 'Abmelden...',
            },
            becomeAmbassador: {
                title: 'Werden Sie Partner',
                caption: 'Begleiten Sie uns...',
                buttonInvitation: 'Bewerben Sie sich noch heute',
                content: `<li>
                            Schalten Sie exklusive Produktrabatte frei
                        </li>
                        <li>
                            Verdienen Sie zusätzliches Geld
                        </li>
                        <li>
                            Verkaufen Sie tolle Produkte
                        </li>
                        <li>
                            Arbeiten Sie, wo und wann es Ihnen passt
                        </li>`,
            },
            businessShape: {
                title: 'Werde Botschafter',
                legend: {
                    activeTitle: 'Aktiv',
                    activeTooltip: 'Aktiv – Hat im aktuellen Kalendermonat einen Umsatz von über 120 £ erzielt',
                    activeOnFileTitle: 'Teilweise',
                    activeOnFileTooltip: 'Teilweise - hat in einem der letzten drei Kalendermonate einen Umsatz von über 120 £ erzielt',
                    notActiveTitle: 'Nicht aktiv',
                    notActiveTooltip: 'Nicht aktiv - hat in keinem der letzten drei Kalendermonate einen Umsatz von über 120 £ erzielt',
                },
                listItem: {
                    level: 'Ebene {{ level }}',
                    active: 'Aktiv: {{ count }}',
                    activeOnFile: 'Teilweise: {{ count }}',
                    notActive: 'Nicht aktiv: {{ count }}',
                },
                nothingToDisplay: 'Es sind keine Daten zum Anzeigen vorhanden',
            },
            dashboard: {
                title: 'Dashboard',
                linkInvite: 'Einkaufslink teilen',
                monthPerformance: {
                    totalSales: 'Gesamtumsatz Veranstaltung',
                    totalSalesExplain: 'D. Gesamtumsatz der gesamten Organisation aus abgeschlossenen Veranstaltungen',
                    avgSalesPerEvent: 'Durchschnittlicher Umsatz pro Veranstaltung',
                    avgSalesPerEventExplain: 'E. Gesamter Veranstaltungsumsatz geteilt durch die Gesamtzahl der Veranstaltungen',
                    projectedSales: 'Geplante Eventverkäufe',
                    projectedSalesExplain: 'Geplante Eventverkäufe = D + (B x E)',
                    eventsBooked: 'Gebuchte Veranstaltungen',
                    thisMonth: 'Diesen Monat',
                    lastMonth: 'Letzter Monat',
                    thisMonthExplain: 'B. Gesamtzahl der in diesem Monat organisationsweit gebuchten Veranstaltungen',
                    thisMonthSoFarExplain: 'A. Gesamtzahl der bisher in diesem Monat unternehmensweit abgeschlossenen Events',
                    lastMonthExplain: 'C. Gesamtzahl der im letzten Monat unternehmensweit abgeschlossenen Events',
                    eventsCompleted: 'Abgeschlossene Veranstaltungen',
                    eventPerformance: 'Veranstaltungsperformance',
                },
                pathway: {
                    programmeTitle: 'Leadership-Programm',
                    lastSeason: 'Letzte Saison',
                    nextSeason: 'Nächste Saison*',
                    thisSeason: 'Diese Saison',
                    explainer: '* Ihre Mitgliedschaft für die nächste Saison wird nach Ablauf des Incentive-Zeitraums bestätigt.',
                    club250Title: '250 verein',
                    club250Tooltip: 'Erreichen Sie einen Umsatz von über 250 £ in einem Kalendermonat, um Teil des 250er-Clubs zu werden',
                    monthsInClub: 'Monate im Verein',
                    quickStartTitle: 'QuickStart-Meilensteine',
                    travelIncentiveTitle: 'Reise-Incentive',
                    competitionProgressTitle: 'Wettbewerbsverlauf',
                    incentivesTitle: 'Anreize',
                },
                rankContainer: {
                    currentTitle: 'Aktueller Titel',
                    nextTitle: 'Nächster Titel',
                },
                ranking: {
                    achievementAwards: 'Auszeichnungen',
                    noContent: 'Es wurden keine Rankings gefunden',
                },
                recruitment: {
                    recruitingPerformance: 'Rekrutierungsleistung',
                    newPersonalThisMonth: 'Neue persönliche Rekruten in diesem Monat',
                    newPersonalTeamThisMonth: 'Neue Rekruten in Ihrem persönlichen Team in diesem Monat',
                    newInDownlineThisMonth: 'Neue Rekruten in Ihrer Downline in diesem Monat',
                    partnersInCommunity: 'Anzahl der Partner in Ihrer Community',
                    totalPartnersInDownline: 'Gesamtzahl der Partner in Ihrer Downline',
                    partnersInPersonalTeam: 'Anzahl der Partner in Ihrem persönlichen Team',
                },
                stats: {
                    activeOnFilePartnersTooltip_one: 'Die monatliche Qualifikation für {{ rankLabel }} beträgt {{ count }} Aktiver persönlich gesponserter Partner',
                    activeOnFilePartnersTooltip_other: 'Die monatliche Qualifikation für {{ rankLabel }} beträgt {{ count }} Aktive persönlich gesponserte Partner',
                    ambassadorKitTooltip: 'Sie müssen das Starter Kit gekauft haben',
                    ambassadorLegsCountTooltip_one: 'Monatliche Qualifikation für {{ rankLabel }} ist {{ count }} Aktiver Partner in Ihrem Team',
                    ambassadorLegsCountTooltip_other: 'Die monatliche Qualifikation für {{ rankLabel }} beträgt {{ count }} Aktive Partner in Ihrem Team',
                    gvTooltip: 'Persönliche Teamverkäufe entsprechen allen PTS-Verkäufen abzüglich Rabatt. Die Qualifikation beträgt {{ count }}',
                    pvTooltip: 'Der persönliche Umsatz entspricht der Gesamtbestellung abzüglich Rabatt: {{ rankLabel }} qualifikation ist {{ count }}',
                    pvTooltip150: 'Die Partnerqualifikation erfordert mindestens 120 persönliche Verkäufe',
                    ovTooltip: 'Der Gesamttitelverkauf für die monatliche Qualifikation {{ rankLabel }} beträgt {{ count }}',
                    uvTooltip: 'Unbelastete persönliche Teamverkäufe entsprechen allen PTS, ausgenommen Verkäufe von TL-fördernden Downline-Partnern. Die Qualifikation ist {{ count }}',
                    qualifiedFrontlineLeadersCountTooltip_one: 'Die monatliche Qualifikation für {{ rankLabel }} beträgt {{ count }} Qualifizierter Teamleiter der 1. Ebene in Ihrem Team.',
                    qualifiedFrontlineLeadersCountTooltip_other: 'Die monatliche Qualifikation für {{ rankLabel }} beträgt {{ count }} qualifizierte Teamleiter der 1. Ebene in Ihrem Team.',
                    personalRecruitsCount_one: 'Die monatliche Qualifikation für {{ rankLabel }} ist {{ count }} Aktiver persönlicher Partner in Ihrem Team.',
                    personalRecruitsCount_other: 'Die monatliche Qualifikation für {{ rankLabel }} beträgt {{ count }} aktive persönliche Partner in Ihrem Team.',
                    qualifiedBranches_one: 'Die monatliche Qualifikation für {{ rankLabel }} beträgt {{ count }} Qualifizierte Partner-Etappe',
                    qualifiedBranches_other: 'Die monatliche Qualifikation für {{ rankLabel }} beträgt {{ count }} Qualifizierte Partner-Legs',
                    qualifiedLeaderLegsCount_one: 'Die monatliche Qualifikation für {{ rankLabel }} beträgt {{ count }} Qualifizierter Leader Leg',
                    qualifiedLeaderLegsCount_other: 'Die monatliche Qualifikation für {{ rankLabel }} beträgt {{ count }} Qualifizierte Leader Legs',
                    qualifiedOrgLeaderCountTooltip: 'Gesamtzahl qualifizierter Führungskräfte in Ihrer Organisation',
                    metricNotRequired: 'Diese Metrik ist für den Titel nicht erforderlich',
                },
            },
            downline: {
                title: 'Downline Explorer',
                noContent: 'Aktuell befinden sich keine Partner in Ihrer Downline.',
                progressTooltip: 'Aufstieg zum Teamleiter',
                aofFilter: 'Aktiver Typfilter',
                level: 'Ebene {{level}}',
                upToMentor: 'Bis zum $t(common.mentor)',
                upToMe: 'Zurück zu mir',
                filterOptions: {
                    all: 'Alle',
                    n1: 'N1',
                    n2: 'N2',
                    n3: 'N3',
                    n4: 'N4',
                    n5: 'N5',
                },
            },
            errorPage: {
                title: 'Es scheint, als wäre etwas schiefgelaufen.',
                mainPageButton: 'Hauptseite',
                content: 'Gehen Sie zurück zur Hauptseite oder melden Sie sich ab',
            },
            eventInvite: {
                inviteTitle: 'Melden Sie sich unten für die Veranstaltung an',
                sentTitle: 'Einladung verschickt',
                sentContent: 'Suchen Sie in Ihrer E-Mail nach dem Link zur Veranstaltungseinladung.',
                sendAnotherButton: 'Senden Sie eine weitere',
            },
            milestone: {
                consistent: 'Konsistent',
                newLeader: 'Neue Leader-Einträge',
                activity: 'Aktivität',
                sales: 'Verkäufe',
                recruiting: 'Rekrutierung',
                promoting: 'Förderung',
                leadership: 'Führung',
                overall: 'Gesamt',
                businesDevelopment: {
                    gold: 'Gold-Mitglied',
                    silver: 'Silber-Mitglied',
                    bronze: 'Bronze-Mitglied',
                },
                quickStart: {
                    milestonesLabel: 'QuickStart-Meilensteine',
                    short: {
                        kitBoost: 'KB',
                        kitRefund: 'KR',
                        businessBoost: 'BB',
                        doublePromotion: 'DPB',
                    },
                    full: {
                        kitBoost: 'Kit-Boost',
                        kitRefund: 'Kit-Rückerstattung',
                        businessBoost: 'Geschäftsschub',
                        doublePromotion: 'Doppelte Beförderung',
                    },
                },
                tooltips: {
                    pathway: {
                        travel: 'Reise-Incentive',
                        business: 'Leadership-Programm',
                        quick: 'QuickStart-Meilensteine',
                        wildcard: 'Wettbewerbsverlauf',
                    },
                },
            },
            event: {
                campaignDate: 'Kampagnendatum',
                eventDate: 'Veranstaltungsdatum',
                message: {
                    invitationToEmailSent: 'Die Veranstaltungseinladung wurde an Ihre E-Mail gesendet',
                    created: 'Party erfolgreich erstellt',
                    updated: 'Veranstaltung erfolgreich aktualisiert',
                },
                typeLabel: {
                    productShowcase: 'Produktpräsentation',
                    getTogether: 'Zusammenkommen',
                    campaignLaunch: 'Kampagnenstarts',
                    generalMarketing: 'Hauptversammlung',
                    recruiting: 'Rekrutierung',
                },
                calendar: {
                    totalInvites: '{{ count }} einladungen versendet',
                    totalSalesAmount: 'Gesamtverkaufsbetrag',
                    eventGuestsStatus: '{{goingGuests}} ja, {{tentativeGuests}} vorläufig, {{awaitingGuests}} wartend, {{declinedGuests}} abgelehnt',
                    noEvent: 'Es gibt heute keine Veranstaltungen',
                    breakdown: '{{ val }} abbauen',
                    totalEvents: 'Party se gesamt',
                    salesAmount: 'Verkaufsbetrag',
                    avgEventSales: 'Durchschnittlicher Umsatz pro Veranstaltung',
                    avgCustomers: 'Durchschnittliche Kundenzahl pro Veranstaltung',
                },
                component: {
                    eventLocation: 'Veranstaltungsort',
                    address: 'Adresse',
                    liveStreamTitle: 'Link zum Live-Stream-Shopping',
                    allowToInvite: 'Gästen erlauben, Gäste einzuladen',
                    shippingDetailsTitle: 'Dies ist die Standardadresse, an die Kunden ihre Eventbestellungen liefern lassen können',
                    eventName: 'Party name',
                    eventDetails: 'Veranstaltungsdetails',
                    eventHost: 'Veranstalter',
                    eventType: 'Veranstaltungstyp',
                    eventTakesPlace: 'Veranstaltung findet statt',
                    shopOnlineFrom: 'Kaufen Sie online bei',
                    shopOnlineFromHelper: 'Ihr Online-Shop kann vor und nach der Veranstaltung geöffnet sein, um veranstaltungsbezogene Bestellungen entgegenzunehmen.',
                    existingHost: 'Vorhandener Host',
                    newHost: 'Neuer Gastgeber',
                    noHost: 'Kein Gastgeber',
                    noHostCaption: 'Für diese Veranstaltung ist kein Gastgeber erforderlich',
                    deliveryDetails: 'Lieferdetails',
                    descriptionPlaceholder: 'Fügen Sie eine veranstaltungsbeschreibung hinzu',
                },
                create: {
                    title: 'Party erstellen',
                    whoIsHosting: 'Wer ist Gastgeber der Veranstaltung?',
                    selectHostess: 'Wählen Sie Hostess',
                    backToHost: 'Zurück zur Gastgeberauswahl',
                    existingHosts: 'Liste der vorhandenen Hosts',
                    selectHost: 'Host suchen',
                    createEventTypeTitle: 'Party typ erstellen',
                    eventType: 'Welche Art von Veranstaltung erstellen Sie?',
                    typeParty: 'Party oder Gemeinschaftsveranstaltung',
                    recruiting: 'Rekrutierung',
                    comingSoon: 'Demnächst verfügbar',
                    knownHostsList: 'Liste bekannter Hosts',
                    editEventTitle: 'Bearbeiten Party',
                },
                invitation: {
                    myHostApp: 'Meine Host-App',
                    loginTo: 'Melden Sie sich an $t(view.event.invitation.myHostApp)',
                    customerSignUpDescription: `WoMöchten Sie Ihre Freunde einladen?
            Klicken Sie hier, um sich für die Party-App anzumelden.
            Hier können Sie alle Partys verfolgen, an denen Sie teilnehmen
            und erhalten Sie Benachrichtigungen über exklusive Angebote.`,
                    invitedTo: 'Sie sind dazu eingeladen',
                    hostParty: 'gastgeber dieser Party',
                    partnerForEvent: 'Partner für die Veranstaltung',
                    hostOfThisEvent: 'Gastgeber dieser Veranstaltung',
                    confirmAndUnderstood: 'Ich bestätige, dass ich die gelesen und verstanden habe',
                    declineInvite: 'Sind Sie sicher, dass Sie diese Einladung ablehnen möchten?',
                    buttonTooltip: 'Um diese Aktion durchführen zu können, müssen Sie den Allgemeinen Geschäftsbedingungen zustimmen',
                    inviteToEvent: 'Zur Veranstaltung einladen',
                    invitationFrom: 'Einladung von',
                    eventInvitation: 'Party Einladung',
                },
                view: {
                    bonusLevel: 'Bonus level',
                    range: 'Reichweite',
                    reward1: 'Belohnung 1',
                    reward2: 'Belohnung 2',
                    shopOnlineFrom: 'Online einkaufen bei',
                    eventTakesPlace: 'Party Findet Statt',
                    hostName: 'Host Name',
                    eventDescription: 'Party beschreibung',
                    event: 'Party',
                    eventSummary: 'Zusammenfassung der Veranstaltung',
                    editButton: 'Veranstaltung bearbeiten',
                    cancelButton: 'Veranstaltung absagen',
                    cancelEventMessage: 'Sind Sie sicher, dass Sie diese Veranstaltung absagen möchten?',
                    inviteGuests: 'Gäste einladen',
                    invitations: 'Einladungen',
                    hostNeedsToAccept: 'Der Gastgeber muss diese Veranstaltung zunächst akzeptieren, bevor Gäste eingeladen werden können',
                    inviteGuestsViaEmail: 'Laden Sie Gäste per E-Mail ein',
                    invitesSent: '{{ count }} einladungen gesendet',
                    eventOrders: 'Parteibestellungen',
                    noOrdersInEvent: 'Derzeit sind mit dieser Veranstaltung keine Bestellungen verknüpft',
                    placeAnOrder: 'eine Bestellung aufgeben',
                },
            },
            incomeSummary: {
                title: 'Einkommensübersicht',
                commissionStatements: 'Kommissionserklärungen',
                annualTotals: 'Jahresgesamt',
                rank: 'Rang (Karrierestufe)',
            },
            invite: {
                title: 'Einladen',
                shareLink: 'Einen Einladungslink teilen',
                emailInvite: 'Per E-Mail einladen',
                scanQR: 'Scannen Sie den QR-Code, um eine Einladung zu erhalten',
            },
            leaderLegs: {
                title: 'Anführerbeine',
                levelShort: 'E{{level}}',
                level: 'ebene {{level}}',
                qualifiedLeg: 'Ist ein qualifizierter Teamleiter leg',
                noData: 'Derzeit sind keine Führungskräfte anzuzeigen.',
                ariaLabel: {
                    expandLevel: 'Ebene erweitern',
                },
            },
            myCustomers: {
                title: 'Meine Kunden',
                directTrue: 'Kundenadressbuch',
                directFalse: 'Kundenaufträge',
                noData: 'Sie haben noch keine Kunden.',
                subscene: {
                    titleLoading: 'Kundenaufträge',
                    title: '{{fullName}} bestellungen',
                },
                edit: {
                    title: '{{fullName}} persönliche Daten',
                    titleLoading: 'Persönliche Daten des Kunden',
                    editDetails: 'Persönliche Kundendaten bearbeiten',
                },
            },
            myReports: {
                title: 'Meine Berichte',
            },
            navigation: {
                impersonating: 'Sie geben sich aus als ',
                section: {
                    home: 'Dashboard',
                    downline: 'Downline',
                    earnings: 'Mein Verdienst',
                    orders: 'Meine Bestellungen',
                    events: 'Veranstaltungsplaner',
                    audience: 'Mein Publikum',
                    account: 'Mein Konto',
                },
                item: {
                    dashboard: 'Dashboard',
                    newEnrollees: 'Meine Neuen Partner',
                    team: 'Mein Team',
                    leaders: 'Meine Führer',
                    businessShape: 'Meine Geschäftsform',
                    downline: 'Meine Downline',
                    commissions: 'Meine Provisionen',
                    reports: 'Meine Berichte',
                    storeCredit: 'Ladenguthaben',
                    orders: 'Meine Bestellungen',
                    createEvent: 'Erstellen Sie ein Party',
                    events: 'Meine Party',
                    shareLink: 'Teilen Sie den Anmeldelink',
                    customers: 'Meine Kunden',
                    endImpersonating: 'Beenden Sie den Identitätswechsel',
                    profile: 'Persönliche Informationen',
                    logout: 'Abmelden',
                },
            },
            newStarters: {
                title: 'Neue Partner',
                enrolleeOrders: 'Bestellungen von {{ fullName }}',
                breakawayProgress: 'Aufstieg zum Teamleiter',
                enrolleeAchievedBreakaway: 'Diese Person hat bereits den Titel „Teamleiter“ erlangt',
                breakawayAchieved: 'Erreichter Teamleiter',
                noData: 'Derzeit können keine neuen Partner angezeigt werden.',
                description: {
                    personalSales: 'Erfordert {{ amount }} persönliche Verkäufe im Zeitraum von {{days}} Tagen seit dem Beitritt',
                    newRecruits: 'Erfordert 2 neue Rekruten in einem Zeitraum von {{days}} Tagen seit dem Beitritt',
                    doublePromotion: 'Erfordert die Beförderung zum Teamleitertitel innerhalb von 3 Kalendermonaten nach dem Beitritt',
                },
                tooltip: {
                    rankAdvancement: 'Der Rangaufstiegsbonus wird verdoppelt, wenn der Teamleitertitel innerhalb der ersten 3 Monate nach dem Beitritt erreicht wird',
                },
            },
            orders: {
                title: 'Bestellungen',
            },
            noPermission: {
                ambassadorKit: {
                    buyButton: 'Jetzt kaufen',
                    loginText: 'Sie sind als {{name}} angemeldet. Um fortfahren zu können, müssen Sie das Starter-Kit erwerben.',
                },
                suspended: {
                    message: 'Ihr Konto ist vorübergehend gesperrt. Kontaktieren Sie den Support für weitere Informationen.',
                },
                termsAndConditions: {
                    agree: 'Ich stimme den Allgemeinen Geschäftsbedingungen zu',
                },
                underInspection: {
                    confirmPrompt: 'Bitte bestätigen Sie Ihre Bankkontodaten erneut, damit wir Ihre monatlichen Überschreibungsprovisionen weiterhin zahlen können',
                },
            },
            profile: {
                title: 'Profil',
                bankDetails: {
                    title: 'Bankverbindung',
                },
                businessDetails: {
                    title: 'Geschäftsdetails',
                    baseAddressSelect: 'Rechnungsempfänger',
                    propertyInfoDisplay: 'Aktuelle Rechnungsadresse',
                },
                termsAndConditions: {
                    readTheLatest: 'Lesen Sie die neuesten {{ termsType }} $t(common.termsAndConditions).',
                    readDocument: 'Dokument lesen',
                },
                personalDetails: {
                    title: 'Persönliche Details',
                },
            },
            register: {
                content: {
                    title: 'Treten Sie der Community bei',
                    titleLoggedIn: 'Wir benötigen einige zusätzliche Angaben von Ihnen',
                    confirmation: 'Ich bestätige, dass ich die',
                },
                customerRegistration: {
                    guestInfo: 'Treten Sie unserer App als Kunde bei',
                },
                hostessRegistration: {
                    form: {
                        guestInfo: 'Sie müssen sich registrieren, bevor Sie auf Veranstaltungseinladungen antworten können.',
                    },
                },
            },
            registerVerification: {
                title: 'Bitte geben Sie zusätzliche Informationen an',
                drivingLicense: {
                    number: 'Führerscheinnummer',
                },
                passport: {
                    label: 'MRZ-Nummer des Reisepasses',
                    content: 'Die MRZ-Nummer ist die lange Nummer, die aus Ziffern, Buchstaben und Winkeln besteht und sich am unteren Rand eines Reisepasses befindet.',
                    imgalt: 'MRZ-Beispiel',
                },
            },
            reports: {
                title: 'Berichte',
                commissionPeriod: 'Provisionszeitraum',
                noReports: 'Für diesen Zeitraum sind keine Berichte verfügbar',
            },
            risingStars: {
                title: 'Mein Team',
                header: {
                    sortPS: 'PS',
                    sortAOF: 'Aktiv',
                    sortBreakaway: 'Ausbrechen',
                },
                legsList: {
                    noTeam: 'Aktuell ist kein Team anzuzeigen.',
                    progressToLeader: 'Aufstieg zum Teamleiter',
                },
            },
            sendRegistration: {
                inviteTitle: 'Ihre Registrierungseinladung',
                afterRegistration: {
                    emailSent: `
                            Die E-Mail wurde an
                            <span class="AfterRegistration__Email">{{email}}</span> gesendet.
                            Sie Ihren Posteingang!
                    `,
                    spamMessage: `
                            Wenn Sie es nicht finden können, überprüfen Sie Ihren Spam-Ordner.
                            Wenn Sie keine E-Mail erhalten haben, senden Sie das Formular erneut ab
                            oder kontaktieren Sie uns über $t(common.supportEmail)
                    `,
                    disabledForX: 'deaktiviert für {{number}}s',
                },
            },
            storeCredit: {
                title: 'Ladenguthaben',
                balance: 'Kontostand',
                transactions: {
                    title: 'Transaktionen',
                    table: {
                        date: 'Datum',
                        orderNumber: 'Bestellnummer',
                        description: 'Beschreibung',
                        change: 'Eine Veränderung',
                        empty: 'Es sind noch keine Transaktionen anzuzeigen',
                    },
                },
            },
        },
        projectComponents: {
            addressSelect: {
                keepTyping: '{{count}} spiele. Geben Sie weiter ein, um bessere Vorschläge zu erhalten',
                broadSearch: 'Ihre Suche war zu allgemein. Geben Sie weiter ein, um ein besseres Ergebnis zu erhalten.',
                label: 'Adresse',
            },
            agreedToReceiveEmail: {
                tooltip: `Durch das Hochladen von Kontaktinformationen können Sie Partyeinladungen
                    und Einladungen zum Beitritt zu Ihrem Team versenden.
                    Ihre Kontakte abonnieren keine Marketing-E-Mails, es sei denn,
                    sie melden sich separat an. Weitere Informationen finden Sie
                    in Ihrer Partnerschaftsvereinbarung.`,
                label: 'Sie müssen die Bedingungen lesen, bevor Sie sie akzeptieren.',
            },
            camera: {
                allowToUseCamera: 'Bitte erlauben Sie der App, Ihre Kamera zu verwenden, um ein Bild aufzunehmen',
                takePhoto: 'Ein Foto machen',
                retakePhoto: 'Erneutes Aufnehmen eines Fotos',
            },
            emailInvite: {
                disclaimer: `Diese Seite ist durch reCAPTCHA geschützt und das Google
                <a href="https://policies.google.com/privacy">Datenschutzrichtlinie</a> und
                <a href="https://policies.google.com/terms">Servicebedingungen</a>.`,
            },
            enrolleeLegItem: {
                ambassadorLegsDetails: {
                    noResults: 'Derzeit sind keine qualifizierten persönlichen Teampartner vorhanden.',
                },
                leaderLegsDetails: {
                    noResults: 'Derzeit sind keine qualifizierten Leader Legs vorhanden.',
                },
                status: {
                    y: '<span class="Status__TextColor--fulfilled">J</span> Habe diesen Monat Bestellungen im Wert von über 120 £ aufgegeben',
                    n1: '<span class="Status__TextColor--almost">N1</span> Vor 1 Monat Bestellungen im Wert von über 120 £ aufgegeben',
                    n2: '<span class="Status__TextColor--almost">N2</span> Vor 2 Monaten Bestellungen im Wert von über 120 £ aufgegeben',
                    n3: '<span class="Status__TextColor--insufficient">N3</span> Vor 3 Monaten Bestellungen im Wert von über 120 £ aufgegeben',
                    n4: '<span class="Status__TextColor--insufficient">N4</span> Vor 4 Monaten Bestellungen im Wert von über 120 £ aufgegeben',
                    n5: '<span class="Status__TextColor--insufficient">N5</span> Vor 5 oder mehr Monaten Bestellungen im Wert von über 120 £ aufgegeben',
                    insufficient: '<span class="Status__TextColor--insufficient">Rot</span> = Inaktiv',
                    almost: '<span class="Status__TextColor--almost">Bernstein</span> = Erreicht',
                    fulfilled: '<span class="Status__TextColor--fulfilled">Grün</span> = Aktiv',
                    risingStarTooltip: '<span><span class="Status__TextColor--fulfilled">Grün</span> = Erreicht</span>',
                },
                myLeadersHeaderLabel: '{{ count }} Aktiv Partners',
            },
            guestRegistrationForm: {
                tncLabel: 'Ich bestätige, dass ich die',
            },
            leaderLegsStatusTooltip: {
                content: `<span>
            Die Mindestqualifikation für den Leiterabschnitt erfordert:
        </span>
        <span>
            Persönlicher Umsatz größer oder gleich {{ps}}
        </span>
        <span>
            Qualifizierte Partner mindestens {{personalRecruits}}
        </span>
        <span>
            Persönlicher Teamumsatz größer oder gleich {{gv}}
        </span>`,
            },
            orderHeaderTitle: {
                originalId: 'Original: #{{ referenceId }}',
                psPositive: '+ PS {{ ps }}',
                psNegative: '- PS {{ ps }}',
            },
            ordersList: {
                noOrders: 'Es sind noch keine Bestellungen anzuzeigen',
            },
            personalDetails: {
                hideFromSearch: 'Vor der Suche verbergen',
            },
            risingStarsStatusTooltipContent: {
                content: 'Für die Partnerqualifizierung sind mindestens {{ ps }} Personal Sales',
            },
            uploadDialogContent: {
                header: 'Profilbild hochladen',
            },
        },
        utils: {
            rankRequirementManager: {
                starterKit: 'Starterpaket',
                personalRecruits: 'Aktive persönliche Partner',
                qualifiedBranches: 'Aktive Partnerbeine',
                activeOnFilePartners: 'Aktiv bei Datei Persönliche Partner',
                qualifiedFrontlineLeaders: 'Qualifizierter Teamleiter Beine der 1. Stufe',
                pv: 'Persönlicher Verkauf',
                gv: 'Persönlicher Teamverkauf',
                uv: 'Unbelasteter persönlicher Teamverkauf',
                ov: 'Titelverkäufe insgesamt',
                leaderLegs: 'Qualifizierter Teamleiter Beine',
                ambassadorLegs_one: 'Aktiver persönlicher Partner',
                ambassadorLegs_other: 'Aktiver persönlicher Partners',
                qualifiedLeaders: 'Qualifizierte Führungskräfte',
            },
        },
    },
};
