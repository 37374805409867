
import { Component, Vue } from 'vue-property-decorator';
import noPermission from '@/modules/NoPermission';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { AssetType } from '@/api/graphQL/graphNodes/types';
import FileViewer from '@/projectComponents/fileViewer/index.vue';

@Component({
    components: {
        FileViewer,
    },
})
export default class NoPermissionTermsAndConditions extends Vue {
    @Get(noPermission) private termsAndConditions!: AssetType|null;

    @Get(noPermission) private termsAndConditionsLoaded!: boolean;

    @Get(noPermission) private visibleLoaderLoading!: boolean;

    @Get(noPermission) private tncErrors!: string;

    @Sync(noPermission) private termsAndConditionsAgreed!: boolean;

    private termsAndConditionEnable = false;

    private isDocumentLoading = true;

    async handleSubmit() {
        try {
            await noPermission.updateTermsAndConditions();
            if (this.tncErrors === '') {
                this.$router.push({ name: 'dashboard' })
                    .catch(() => {});
            }
        // eslint-disable-next-line no-empty
        } finally {}
    }

    handleLoaded() {
        setTimeout(this.setupReadDocument, 1000);
    }

    setupReadDocument() {
        this.isDocumentLoading = false;

        const el = this.$refs.termsAndConditionsPreviewContainer as Element;
        if (el.clientHeight > 0 && el.scrollHeight > el.clientHeight) {
            el.addEventListener('scroll', this.handleDocumentEnd);
        } else {
            this.termsAndConditionEnable = true;
        }
    }

    handleDocumentEnd(event) {
        const el = this.$refs.termsAndConditionsPreviewContainer as Element;

        const {
            scrollHeight, clientHeight, scrollTop,
        } = event.target;

        if (Math.abs(scrollHeight - clientHeight - scrollTop) < 20) {
            this.termsAndConditionEnable = true;
            el.removeEventListener('scroll', this.handleDocumentEnd);
        }
    }

    mounted() {
        if (!this.termsAndConditionsLoaded) {
            noPermission.loadTermsAndConditions();
        }
    }

    beforeDestroy() {
        noPermission.setTermsAndConditionsAgreed(false);
    }
}

