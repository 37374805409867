import { TermsAndConditionsType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetTermsAndConditionsQuery extends
    AbstractQueryResource<GetTermsAndConditionsResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($type: String) {
            termsAndConditions(type: $type) {
                id,
                createdAt,
                content {
                    id,
                    category,
                    link,
                    fileName,
                    context {
                        metric,
                        value,
                    },
                    title,
                    originalFileName,
                    uploadDate,
                }
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getTermsAndConditions';
    }
}

export type GetTermsAndConditionsResultType = {
    termsAndConditions: TermsAndConditionsType|null;
}
