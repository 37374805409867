const asset = `type Asset {
    id: String
    category: String!
    subCategory: AssetCategory
    link: String!
    fileName: String!
    title: String
    context: [Stats]
    originalFileName: String!
    uploadDate: String!
    priority: Int!
}`;

const assetsQuery = `
    assets(category: String): [Asset]
`;

const searchAssetsQuery = `
    searchAssets(query: String!, category: String!, subCategoryId: String,
        limit: Int, offset: Int): [Asset]
`;

const searchAssetsCountQuery = 'searchAssetsCount(query: String!, category: String!, subCategoryId: String): Int';

export {
    asset, assetsQuery, searchAssetsQuery, searchAssetsCountQuery,
};
