
import Vue from 'vue';
import Component from 'vue-class-component';
import authenticator from '@/modules/Auth/services/authenticator';
import dateManager from '@/utils/time';
import localStorageManager from '@/utils/local-storage-manager';
import { RETURN_ROUTE_LOCAL_STORAGE_KEY } from '@/modules/System/constants';

@Component
export default class Auth extends Vue {
    async created() {
        await authenticator.finishLogin();

        const returnRouteData = JSON
            .parse(localStorageManager.getStorage(RETURN_ROUTE_LOCAL_STORAGE_KEY));

        let shouldRedirectToSaved = false;

        if (returnRouteData && returnRouteData.expirationDate) {
            shouldRedirectToSaved = returnRouteData.expirationDate && dateManager
                .isBefore(dateManager.getCurrentDateTime(), returnRouteData.expirationDate);
        }

        if (shouldRedirectToSaved) {
            this.$router.push({
                name: returnRouteData.returnRoute.name,
                params: returnRouteData.returnRoute.params,
                query: returnRouteData.returnRoute.query,
            });
            localStorageManager.clearStoreState(RETURN_ROUTE_LOCAL_STORAGE_KEY);
        } else {
            this.$router.push({ name: 'dashboard' })
                .catch(() => {});
        }
    }
}
