
import { Vue, Component, Prop } from 'vue-property-decorator';
import RisingStarsStatusTooltip from './risingStarsStatusTooltip.vue';

@Component({
    components: {
        RisingStarsStatusTooltip,
    },
})
export default class RisingStarsStatus extends Vue {
    @Prop() private status!: string;

    @Prop({ default: 0 }) private statusProgress!: number;

    get classes() {
        return [
            'Status__Element',
            this.calculateContainerColor(this.status),
        ];
    }

    calculateContainerColor(key: string): string {
        switch (key) {
        case 'insufficient':
            return 'Status__Element--insufficient';
        case 'almost':
            return 'Status__Element--almost';
        case 'fulfilled':
            return 'Status__Element--fulfilled';
        case 'disabled':
            return 'Status__Element--disabled';

        default:
            return '';
        }
    }
}
