
import {
    Vue, Component, Prop,
} from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import profile from '@/modules/Profile';
import profilePicture from '@/modules/Profile/profilePicture';
import imageResizeProcessor from '@/modules/Profile/services/imageResizeProcessor';
import { FileType } from '@/components/fileArea/types';
import UploadDialogContent from '@/projectComponents/uploadDialog/index.vue';

@Component({
    components: {
        UploadDialogContent,
    },
})
export default class ProfileUploadDialog extends Vue {
    @Get(profile) private imageDialogOpen!: boolean;

    @Get(profilePicture, 'loading') private uploadLoading!: boolean;

    @Get(profile) private profilePicUpload!: FileType|null;

    @Prop() private image!: string;

    handleImageUploadModalClose() {
        profile.setImageDialogOpen(false);
        profilePicture.clearImageData();
    }

    async handleProfileUpload() {
        // @ts-ignore
        const croppedImg = this.$refs.upload.$refs.uploadContent.$refs.cropArea.$refs.cropper
            .getCroppedCanvas().toDataURL();
        profilePicture.uploadProfilePicture({ img: croppedImg });
    }

    async onFileAdd(files: FileType[]) {
        const file64 = await imageResizeProcessor(files[0].file);
        profilePicture.setImage(file64);
    }

    handleReupload() {
        profilePicture.clearImageData();
    }
}
