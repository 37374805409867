
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

@Component
export default class ProgressBarCircular extends Vue {
    @Prop({ default: 0 }) private value!: number;

    @Prop() private ariaLabel!: string;

    @Prop({ default: false }) private customLabel!: boolean;

    @Prop({ default: '' }) private customLabelContent!: string;

    @Prop({ default: 'medium' }) private size!: string;

    get progressSize(): number {
        if (this.size === 'small') {
            return 20;
        }

        return 60;
    }

    get center(): number {
        return this.progressSize / 2;
    }

    get radius(): number {
        if (this.size === 'small') {
            return 8;
        }

        return 26;
    }

    get strokeWidth(): number {
        if (this.size === 'small') {
            return 3;
        }

        return 4;
    }

    get contentClasses() {
        return [
            'ProgressBarCircular__Content',
            { 'ProgressBarCircular__Content--small': this.size === 'small' },
        ];
    }

    setProgressVal() {
        const circle:SVGCircleElement = this.$refs.fill as SVGCircleElement;

        const radius = circle.r.baseVal.value;
        const circumference = radius * 2 * Math.PI;
        const normalisedValue = this.value > 100 ? 100 : this.value;
        circle.style.strokeDashoffset = '163';
        circle.style.strokeDasharray = '163';

        circle.style.strokeDashoffset = `${circumference - (normalisedValue / 100) * circumference}`;
        circle.style.strokeDasharray = `${circumference}, ${circumference}`;
    }

    get displayValue() {
        const res = this.value > 100 ? 100 : this.value;
        return `${Math.floor(res)}`;
    }

    get elementClasses() {
        return [
            'ProgressBarCircular__Background  ProgressBarCircular__Container',
        ];
    }

    init() {
        this.setProgressVal();
    }

    mounted() {
        this.init();
    }

    @Watch('value')
    onScreenSizeChange() {
        this.setProgressVal();
    }
}
