import API from '@/api/graphQL';
import { AmbassadorsCountsParamsType } from '@/api/graphQL/graphNodes/GetAmbassadorsCountsQuery';

export default class BusinessShapeRepository {
    static getAmbassadorsCounts(data: AmbassadorsCountsParamsType, options: string) {
        return API
            .getAmbassadorsCounts()
            .query(data, {}, options);
    }
}
