
import { AddressSelectOptionsType } from '@/modules/AddressSelect/types';
import addressOption from '@/modules/AddressSelect/addressOption';
import { CGet, CSync } from '@/utils/vuex-module-mutators';
import {
    Vue, Component, Prop, Watch,
} from 'vue-property-decorator';

@Component
export default class AddressSelectItem extends Vue {
    @Prop({ required: true }) id!: string;

    @Prop({ default: '' }) value!: string;

    @Prop({ default: 0 }) level!: number;

    @CGet(addressOption) data!: AddressSelectOptionsType;

    @CGet(addressOption) childOptionIds!: string[];

    @CGet(addressOption) loaded!: boolean;

    @CGet(addressOption) loading!: boolean;

    @CSync(addressOption) containerOpen!: boolean;

    get selectItemClasses() {
        const { value, id } = this;
        return [
            'AddressSelectItem__SelectItem',
            { 'AddressSelectItem__SelectItem--active': value === id },
        ];
    }

    get label(): string {
        const { data } = this;
        const description = data.description !== '' ? `, ${data.description}` : '';
        return `${data.text}${description}`;
    }

    @Watch('containerOpen')
    async loadData(val: boolean) {
        if (!this.loaded && val) {
            addressOption.loadOptions(this.id);
        }
    }

    handleClick(val) {
        this.handleInput(val);
        this.setLabel(this.label);
    }

    handleInput(val:string = '') {
        this.$emit('input', val);
    }

    setLabel(val:string = '') {
        this.$emit('label', val);
    }
}
