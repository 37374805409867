import { MeType } from '@/api/graphQL/graphNodes/types';

const me: MeType = {
    roles: [
        'ROLE_USER',
        'ROLE_ADMIN',
        'ROLE_AMBASSADOR',
    ],
    userName: 'admin@plumtreesystems.com',
};

export { me };
