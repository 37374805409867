const calendar = `type Calendar {
    day: String!
    totalEvents: Int!
    events: [Event]
}`;

const calendarQuery = 'calendar(dateFrom: DateTime, dateTo: DateTime, offset: Int, limit: Int, type: String!, status: String): [Calendar]';
const calendarDailyQuery = 'calendarDaily(dateFrom: DateTime, dateTo: DateTime, offset: Int, limit: Int, type: String!, status: String): [Calendar]';

export {
    calendar, calendarQuery, calendarDailyQuery,
};
