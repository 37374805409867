import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { GetImpersonateResultType } from '@/api/graphQL/graphNodes/GetImpersonateQuery';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import auth from '@/modules/Auth';
import LocalStorageManager from '@/utils/local-storage-manager';
import { Impersonator, RequestCache, CustomAction as Action } from '@plumtreesystems/utils';
import impersonateRepository from './services/impersonateRepository';
import impersonationControls from './controls';

export const IMPERSONATE_ROLE = 'ROLE_IMPERSONATE';

@Module({
    namespaced: true, dynamic: true, store, name: 'impersonation',
})
@AutoMutations
export class Impersonation extends VuexModule {
    private token: string|null = null;

    get impersonating(): boolean {
        return !!this.token;
    }

    @Mutation
    setToken(val: string|null) {
        this.token = val;
    }

    @Action()
    async handleImpersonating(id: string) {
        try {
            impersonationControls.setLoading(true);
            const { impersonateEnrollee }: GetImpersonateResultType = await impersonateRepository
                .impersonate({ enrolleeId: id });

            const defaultImpersonateState: Object = LocalStorageManager
                .getStoreDefaultState();

            store.replaceState({
                ...store.state,
                ...defaultImpersonateState,
            });

            this.reassignImpersonateStoreVersions(defaultImpersonateState);
            RequestCache.clearImpersonateCache();

            Impersonator.setImpersonateToken(impersonateEnrollee);
            this.setToken(impersonateEnrollee);
            await auth.setupUserDetails(this.impersonating);
            auth.addRole(IMPERSONATE_ROLE);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            impersonationControls.setLoading(false);
        }
    }

    @Action()
    reassignImpersonateStoreVersions(state) {
        const versions = LocalStorageManager.getDefaultVersionsObject();
        Object.entries(state).forEach(([key, value]) => {
            versions[key] = LocalStorageManager.calculateStoreVersion(value);
        });

        LocalStorageManager.setImpersonateStoreVersion(versions);
    }

    @Action()
    endImpersonation() {
        const state = LocalStorageManager.getStoreState();

        if (state) {
            store.replaceState({
                ...store.state,
                ...state,
            });
        } else {
            store.replaceState({
                ...store.state,
                ...LocalStorageManager.getStoreDefaultState(),
            });
        }

        this.setToken(null);
        Impersonator.removeImpersonateToken();
    }
}

export default getModule(Impersonation);
