import dateManager from '@/utils/time';
import mockedDateManager from '@/utils/mocked-date-manager';
import { RunsType } from '@/api/graphQL/graphNodes/types';

const currentDate = mockedDateManager.getCurrentDate(dateManager.getDateFormat());

const runs: () => RunsType[] = () => ([
    {
        id: '0',
        from: dateManager.getDateWithOffset(-3, currentDate, undefined, 'month'),
        to: dateManager.getDateWithOffset(-2, currentDate, undefined, 'month'),
        label: `${dateManager.getDateWithOffset(-3, currentDate, 'YYYY MMMM', 'month')}`,
    }, {
        id: '1',
        from: dateManager.getDateWithOffset(-2, currentDate, undefined, 'month'),
        to: dateManager.getDateWithOffset(-1, currentDate, undefined, 'month'),
        label: `${dateManager.getDateWithOffset(-2, currentDate, 'YYYY MMMM', 'month')}`,

    }, {
        id: '2',
        from: dateManager.getDateWithOffset(-1, currentDate, undefined, 'month'),
        to: dateManager.getDateWithOffset(0, currentDate, undefined, 'month'),
        label: `${dateManager.getDateWithOffset(-1, currentDate, 'YYYY MMMM', 'month')}`,
    },
]);

export { runs };
