const countries = `type Countries {
    longLabel: String!
    shortLabel: String!
}`;

const countriesQuery = `
    countries: [Countries]
    regions: [Countries]
`;

export { countries, countriesQuery };
