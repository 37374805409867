// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetSuspendedQuery extends AbstractQueryResource<GetSuspendedResultType> {
    protected getQuery(): string {
        return `query ${this.getName()} {
            profile {
                suspension,
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'GetSuspended';
    }
}

export type GetSuspendedResultType = {
    profile: {
        suspension: boolean;
    }
}
