const leaderBoard = `type Leaderboard {
    position: String!
    previousPosition: String
    enrolleeId: String!
    name: String!
    metricValue: String!
}`;

const achievementAward = `type AchievementAward {
    type: String!
    title: String!
    tooltip: String!,
    description: String!
    leaderboard: [Leaderboard]
}`;

const achievementAwardsQuery = 'achievementAwards(type: String!, limit: Int = 10, skip: Int = 0, query: String = ""): AchievementAward';

const achievementAwardsCountQuery = 'achievementAwardsCount(type: String!, query: String = ""): Int!';

const dashboardAchievementAwardsQuery = 'dashboardAchievementAwards: [AchievementAward]';

export {
    leaderBoard, achievementAward, achievementAwardsQuery,
    achievementAwardsCountQuery, dashboardAchievementAwardsQuery,
};
