// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { MyHostsType } from './types';

export default class GetHostsQuery extends AbstractQueryResource<GetHostsResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($query: String!, $offset: Int, $limit: Int) {
            profile {
                searchHosts(query: $query, offset: $offset, limit: $limit) {
                    id,
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                    secondPhoneNumber,
                    address,
                    secondAddress,
                    shadow,
                    editable,
                },
                searchHostsCount(query: $query),
            }
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getHosts';
    }
}

export type GetHostsResultType = {
    profile: {
        searchHosts: MyHostsType[];
        searchHostsCount: number;
    }
}

export type GetHostsParamsType = {
    limit: number;
    offset: number;
    query: string;
}
