
import { Component, Prop, Vue } from 'vue-property-decorator';

enum ButtonTypeEnum {
    'contained',
    'text',
    'outlined'
}

@Component
export default class LinkButton extends Vue {
    @Prop({ default: '' }) private wrapperClass!: string;

    @Prop({ required: true }) private text!: string;

    @Prop({ default: '' }) private icon!: string;

    @Prop({
        default: ButtonTypeEnum.contained,
        validator: (val: string) => ButtonTypeEnum[val] !== undefined,
    }) private type!: ButtonTypeEnum;

    @Prop({ default: false }) private disabled!: boolean;

    @Prop({ default: '#' }) private href!: string;

    @Prop({ default: '' }) private target!: string;

    get wrapperClasses() {
        return [
            'LinkButton--link',
            this.wrapperClass,
        ];
    }

    get elementClasses() {
        return [
            'LinkButton',
            `LinkButton--${this.type}`,
            this.disabled ? 'LinkButton--disabled' : 'Ripple',
            this.icon ? 'LinkButton--withIcon' : '',
        ];
    }
}
