import { AssetCategoryType } from '@/api/graphQL/graphNodes/types';

const trainingAssetCategories: AssetCategoryType[] = [
    {
        id: '0',
        name: 'temporary.trainingAssetCategories.superStarterAcademy',
        priority: 0,
        icon: null,
    },
    {
        id: '1',
        name: 'temporary.trainingAssetCategories.productExpert',
        priority: 1,
        icon: null,
    },
    {
        id: '2',
        name: 'temporary.trainingAssetCategories.socialMediaSuccess',
        priority: 2,
        icon: null,
    },
    {
        id: '3',
        name: 'temporary.trainingAssetCategories.partyAndEvents',
        priority: 3,
        icon: null,
    },
];

const marketingAssetCategories: AssetCategoryType[] = [
    {
        id: '8',
        name: 'temporary.marketingAssetCategories.catalogs',
        priority: 1,
        icon: null,
    },
    {
        id: '9',
        name: 'temporary.marketingAssetCategories.promoVideos',
        priority: 2,
        icon: null,
    },
    {
        id: '10',
        name: 'temporary.marketingAssetCategories.promotionMaterial',
        priority: 3,
        icon: null,
    },
];

const assetCategories: (type: string) => AssetCategoryType[] = (type) => {
    if (type === 'marketing') {
        return marketingAssetCategories;
    }

    if (type === 'help_support') {
        return [];
    }

    return trainingAssetCategories;
};

export { assetCategories, trainingAssetCategories, marketingAssetCategories };
