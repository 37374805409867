
import { Vue, Component, Prop } from 'vue-property-decorator';
import PropertyInfoDisplay from '@/projectComponents/propertyInfoDisplay/index.vue';
import { EnrolleeType } from '@/api/graphQL/graphNodes/types';

@Component({
    components: {
        PropertyInfoDisplay,
    },
})
export default class EventPersonDetails extends Vue {
    @Prop({ required: true }) private person!: EnrolleeType|null;

    @Prop({ required: true }) private title!: string;
}
