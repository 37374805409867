import { CountriesType } from '@/modules/Event/types';

const countries: () => CountriesType[] = () => ([
    {
        shortLabel: 'FR',
        longLabel: 'common.countries.france.title',
    },
    {
        shortLabel: 'DE',
        longLabel: 'common.countries.germany.title',
    },
    {
        shortLabel: 'IT',
        longLabel: 'common.countries.italy.title',
    },
    {
        shortLabel: 'ESP',
        longLabel: 'common.countries.spain.title',
    },
    {
        shortLabel: 'PL',
        longLabel: 'common.countries.poland.title',
    },
    {
        shortLabel: 'RO',
        longLabel: 'common.countries.romania.title',
    },
    {
        shortLabel: 'SK',
        longLabel: 'common.countries.slovakia.title',
    },
    {
        shortLabel: 'GB',
        longLabel: 'common.countries.greatBritain.title',
    },
]);

const regions: () => CountriesType[] = () => ([
    {
        shortLabel: 'FR',
        longLabel: 'common.countries.france.title',
    },
    {
        shortLabel: 'DE',
        longLabel: 'common.countries.germany.title',
    },
    {
        shortLabel: 'IT',
        longLabel: 'common.countries.italy.title',
    },
    {
        shortLabel: 'ESP',
        longLabel: 'common.countries.spain.title',
    },
    {
        shortLabel: 'PL',
        longLabel: 'common.countries.poland.title',
    },
    {
        shortLabel: 'RO',
        longLabel: 'common.countries.romania.title',
    },
    {
        shortLabel: 'SK',
        longLabel: 'common.countries.slovakia.title',
    },
    {
        shortLabel: 'UK',
        longLabel: 'common.countries.unitedKingdom.title',
    },
]);

export { countries, regions };
