import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import {
    EnrolleeCustomerOrderType, BaseOrderType,
} from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { GetCustomerOrdersParamsType } from '@/api/graphQL/graphNodes/GetCustomerOrdersQuery';
import NewStartersRepository from './services/newStartersRepository';
import { initialEnrolleeOrderData } from './defaults';

@Module({
    namespaced: true, dynamic: true, store, name: 'newStartersOrders',
})
@AutoMutations
export class NewStartersOrders extends VuexModule {
    private orders: EnrolleeCustomerOrderType = initialEnrolleeOrderData();

    private searchQuery: string = '';

    private searchLoading: boolean = false;

    private ordersLoading: boolean = false;

    private displaySearchResults: boolean = false;

    private displaySearch: boolean = false;

    private expandedOrders: string[] = [];

    private searchedOrdersOptions: BaseOrderType[] = [];

    private offset: number = 0;

    private limit: number = 10;

    private minSearchLength: number = 3;

    private total: number = 0;

    private loadingInBackground: boolean = false;

    private loading: boolean = false;

    private searchedOrders: BaseOrderType[] = [];

    private fullName: string = '';

    @Mutation
    setDisplaySearchResults(val: boolean) {
        this.displaySearchResults = val;
    }

    @Mutation
    public setOffset(val: number = 0) {
        this.offset = val;
    }

    @Mutation
    setSearchedOrdersOptions(val: BaseOrderType[]) {
        this.searchedOrdersOptions = [...val];
    }

    @Mutation
    setSearchedOrders(val: BaseOrderType[]) {
        this.searchedOrders = [...val];
    }

    @Mutation
    setSearchQuery(val: string) {
        this.searchQuery = val;
    }

    @Mutation
    public setLoadingInBackground(val: boolean) {
        this.loadingInBackground = val;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setSearchLoading(val: boolean) {
        this.searchLoading = val;
    }

    @Mutation
    public setTotal(val: number) {
        this.total = val;
    }

    @Mutation
    public setOrdersData(val: EnrolleeCustomerOrderType = initialEnrolleeOrderData()) {
        this.orders = { ...val };
    }

    @Mutation
    toggleExpandedOrdersList(val: string) {
        if (this.expandedOrders.find((item) => item === val)) {
            const index = this.expandedOrders.findIndex((item) => item === val);
            this.expandedOrders.splice(index, 1);
        } else {
            this.expandedOrders.push(val);
        }
    }

    @Mutation
    toggleShowSearch() {
        this.displaySearch = !this.displaySearch;
    }

    @Mutation
    clearExpandedOrdersList() {
        this.expandedOrders = [];
    }

    @Mutation
    setFullName(name: string) {
        this.fullName = name;
    }

    @Action()
    public async getOrders(data: {
        enrolleeId: string,
        loadPage?: boolean,
        offset?: number,
    }) {
        const { enrolleeId, loadPage = false, offset = 0 } = data;

        try {
            if (loadPage) {
                this.setLoadingInBackground(true);
            } else {
                this.setLoading(true);
            }

            const params: GetCustomerOrdersParamsType = {
                id: enrolleeId,
                limit: this.limit,
                offset,
                query: '',
            };

            const result = await NewStartersRepository.getOrders(params);
            this.setOrdersData(result.enrollee);
            this.setOffset(offset);
            this.setTotal(result.enrollee.searchOrdersCount);
            this.setFullName(`${result.enrollee.firstName} ${result.enrollee.lastName}`);
            this.clearExpandedOrdersList();
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoadingInBackground(false);
            this.setLoading(false);
        }
    }

    @Action()
    async searchOrders(data: {
        query?: string|null,
        selectedSearch?: boolean,
        offset?: number,
        enrolleeId: string,
    }
    = { enrolleeId: '', query: null, selectedSearch: false }) {
        const {
            enrolleeId, query, selectedSearch, offset = 0,
        } = data;

        try {
            if (selectedSearch) {
                this.setSearchLoading(true);
            }

            const params: GetCustomerOrdersParamsType = {
                id: enrolleeId,
                limit: this.limit,
                offset,
                query: query || query === '' ? query : this.searchQuery.replace('#', ''),
            };

            const result = await NewStartersRepository
                .getOrders(params);

            const { searchOrders, searchOrdersCount } = result.enrollee;

            if (selectedSearch) {
                this.setSearchedOrders(searchOrders);
                this.setTotal(searchOrdersCount);
            } else {
                this.setSearchedOrdersOptions(searchOrders);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            if (selectedSearch) {
                this.setSearchLoading(false);
            }
        }
    }
}

export default getModule(NewStartersOrders);
