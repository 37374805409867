import API from '@/api/graphQL';

export default class CountriesRepository {
    static getCountries(options = {}) {
        return API
            .getCountries()
            .query({}, options);
    }

    static getRegions(options = {}) {
        return API
            .getRegions()
            .query({}, options);
    }
}
