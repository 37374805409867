
import { StatsType } from '@/api/graphQL/graphNodes/types';
import { currencySign } from '@/modules/StoreCredit/helpers';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class StoreCreditBalance extends Vue {
    @Prop({ required: true }) private balance!: StatsType;

    formatCents(unformatted) {
        return Number(unformatted).toFixed(2);
    }

    get currencyPrefix() {
        return currencySign(this.balance.metric);
    }
}
