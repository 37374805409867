import { SelectOptionsType } from '@/components/select/types';

export const timeOptions = (interval: number) => {
    const data = [] as SelectOptionsType[];
    for (let hr = 0; hr < 24; hr++) {
        for (let min = 0; min < 60; min += interval) {
            const value = `${hr.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}`;
            data.push({ name: value, value });
        }
    }
    return data;
};

export function getNextHighestIndex(arr, value) {
    let i = arr.length;
    while (arr[--i] >= value);
    return ++i;
}
