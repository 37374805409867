import RestAPI from '@/api/rest';
import { GetAddressOptionsParamsType } from '@/api/rest/Resources/types';

export default class AddressOptionsRepository {
    static getAddressOptions(params: GetAddressOptionsParamsType) {
        return RestAPI
            .getAddress()
            .send(params);
    }
}
