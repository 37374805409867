import { MilestoneType } from '@/api/graphQL/graphNodes/types';

const resolveStatus = (milestone: MilestoneType) => {
    if (milestone.achieved) {
        return 'completed';
    }

    if (milestone.unachievable) {
        return 'unachievable';
    }

    return 'achievable';
};

export default resolveStatus;
