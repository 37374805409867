import { AbstractResource, Methods } from '../AbstractResource';
import { GetAddressOptionsParamsType } from './types';

export default class GetAddressOptions extends AbstractResource <string> {
    private urlParams = {};

    protected noAuthentication: boolean = true;

    protected getAllowedMethods(): Methods[] {
        return [Methods.GET_ALL];
    }

    protected getPath(): string {
        return `/address?${this.formatUrlParams()}`;
    }

    protected formatUrlParams() {
        let res = '';
        Object.keys(this.urlParams).forEach((item, index) => {
            const key = item;
            const val = `${index === 0 ? '' : '&'}${key}=${encodeURIComponent(this.urlParams[item])}`;
            res += val;
        });

        return res;
    }

    public send(params: GetAddressOptionsParamsType): Promise<GetAddressOptionsReturnType> {
        this.urlParams = { limit: 5, ...params };

        return this.getAll();
    }
}

export type GetAddressOptionsReturnType = {
    Items: GetAddressOptionType[];
}

type GetAddressOptionType = {
    Id: string;
    Type: string;
    Text: string;
    Highlight: string;
    Description: string;
}
