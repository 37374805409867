import {
    Mutation, Module, getModule, VuexModule,
} from 'vuex-module-decorators';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AssetType } from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import EventRepository from '../Events/services/eventRepository';

@Module({
    namespaced: true, dynamic: true, store, name: 'eventInvitationTermsAndConditions',
})
@AutoMutations
export class EventInvitationTermsAndConditions extends VuexModule {
    private termsAndConditions: AssetType|null = null;

    private agreed: boolean = false;

    private dialogOpen: boolean = false;

    private loading: boolean = false;

    private loaded: boolean = true;

    @Mutation
    public setTermsAndConditions(val: AssetType|null) {
        this.termsAndConditions = val;
    }

    @Mutation
    public setDialogOpen(val: boolean) {
        this.dialogOpen = val;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setLoaded(val: boolean) {
        this.loaded = val;
    }

    @Mutation
    public setAgreed(val: boolean) {
        this.agreed = val;
    }

    @Action()
    public async getTermsAndConditions(params: { termsType: string, token: string }) {
        try {
            const { token, termsType } = params;
            this.setLoading(true);

            const result = await EventRepository.getTermsAndConditions(token, { type: termsType });

            this.setTermsAndConditions(result.termsAndConditions
                ? result.termsAndConditions.content : null);
            this.setLoaded(true);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(EventInvitationTermsAndConditions);
