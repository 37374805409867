
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { EventType } from '@/api/graphQL/graphNodes/types';
import dateManager from '@/utils/time';

@Component({
    components: {
    },
})
export default class CalendarPill extends Vue {
    @Prop({ required: true }) private day!: string;

    @Prop({ required: true }) private data!: EventType|null;

    get pillClasses() {
        return [
            'CalendarPill__Container',
            this.firstDay ? 'CalendarPill__FirstDay' : '',
            this.lastDay ? 'CalendarPill__LastDay' : '',
        ];
    }

    get pillContentClasses() {
        return [
            'CalendarPill__PillContent',
            { 'CalendarPill__PillContent--cancelled': this.data!.canceled },
            { 'CalendarPill__PillContent--closed': this.data!.closed },
        ];
    }

    get firstDay() {
        if (
            this.data && (dateManager
                .getDateTime(this.data.campaignDateFrom, dateManager.getDateFormat()) === this.day
            )
        ) {
            return true;
        }

        return false;
    }

    get lastDay() {
        if (
            this.data && (dateManager
                .getDateTime(this.data.campaignDateTo, dateManager.getDateFormat()) === this.day
            )
        ) {
            return true;
        }

        return false;
    }

    get title() {
        if (!this.data) {
            return '';
        }

        const { title, eventDateFrom } = this.data;
        return `${dateManager.getDateTime(eventDateFrom, 'HH:mm')} ${title}`;
    }
}
