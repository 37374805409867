import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { RisingStarsType } from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { GetRisingStarsByIdParamsType, GetRisingStarsByIdResultType } from '@/api/graphQL/graphNodes/GetRisingStarsByIdQuery';
import RisingStarsRepository from './services/risingStarsRepository';
import legModule, { Leg } from './requirementRisingStarsLeg';

@Module({
    namespaced: true, dynamic: true, store, name: 'requirementRisingStars',
})
@AutoMutations
export class RequirementRisingStars extends VuexModule {
    private legs: RisingStarsType[] = [];

    private loaded: boolean = false;

    private loading: boolean = false;

    private activeLegDetailsDialog: string = '';

    private expandedRisingStars: string[] = [];

    private loadingInBackground: boolean = false;

    private legsCount: number = 0;

    private limit: number = 20;

    private offset: number = 0;

    private activeSponsorDetailsModal: string = '';

    @Mutation
    public setLoaded(val: boolean) {
        this.loaded = val;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setLoadingInBackground(val: boolean) {
        this.loadingInBackground = val;
    }

    @Mutation
    public setData(val: RisingStarsType[]) {
        this.legs = [...val];
    }

    @Mutation
    public addData(val: RisingStarsType[]) {
        this.legs = [...this.legs, ...val];
    }

    @Mutation
    public setActiveLegDetailsDialog(val: string) {
        this.activeLegDetailsDialog = val;
    }

    @Mutation
    toggleExpandedList(val: string) {
        if (this.expandedRisingStars.find((item) => item === val)) {
            const index = this.expandedRisingStars.findIndex((item) => item === val);
            this.expandedRisingStars.splice(index, 1);
        } else {
            this.expandedRisingStars.push(val);
        }
    }

    @Mutation
    clearExpandedRisingStars() {
        this.expandedRisingStars = [];
    }

    @Mutation
    setLegsCount(val) {
        this.legsCount = val;
    }

    @Mutation
    setOffset(val: number) {
        this.offset = val;
    }

    @Mutation
    setActiveSponsorDetailsModal(val: string) {
        this.activeSponsorDetailsModal = val;
    }

    @Action()
    public clearRisingStarsLegsDialogData() {
        this.setActiveLegDetailsDialog('');
        this.setData([]);
        legModule.setElements([]);
        this.setOffset(0);
        this.setLoaded(false);
        this.clearExpandedRisingStars();
    }

    @Action()
    public async getRisingStars(data: {id: string, loadPage?: boolean, offset?: number }) {
        const { loadPage = false, offset = 0, id } = data;

        try {
            if (loadPage) {
                this.setLoadingInBackground(true);
            } else {
                this.setLoading(true);
            }

            const params: GetRisingStarsByIdParamsType = {
                id,
                limit: this.limit,
                offset,
            };

            const result: GetRisingStarsByIdResultType = await RisingStarsRepository
                .getRisingStarsById(params);

            const {
                risingStars, risingStarsCount,
            } = result.enrollee;

            risingStars
                .sort((a, b) => (a.totals.pv < b.totals.pv ? 1 : -1));

            legModule.setElements([]);
            risingStars.forEach((star) => {
                const leg = new Leg();
                leg.id = star.id;
                leg.data = { ...leg.data, ...star };

                legModule.addElement(leg);
                legModule.loadData(star.id);
            });

            this.setLegsCount(risingStarsCount);

            this.setData(risingStars);
            this.setOffset(offset);

            if (!loadPage) {
                this.setLoaded(true);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoadingInBackground(false);
            this.setLoading(false);
        }
    }
}

export default getModule(RequirementRisingStars);
