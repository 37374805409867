
import { Vue, Component, Prop } from 'vue-property-decorator';
import { OrderType } from '@/api/graphQL/graphNodes/types';
import { PaginationParamsType } from '@/components/pagination/types';
import OrdersListItem from './item.vue';

@Component({
    components: {
        OrdersListItem,
    },
})
export default class OrdersList extends Vue {
    @Prop() private orders!: OrderType[];

    @Prop() private region!: string;

    @Prop() private expandedList!: string[];

    @Prop() private offset!: number;

    @Prop() private total!: number;

    @Prop() private limit!: number;

    @Prop() private loadingInBackground!: boolean;

    @Prop({ default: false }) private showCustomerName!: boolean;

    handleExpandedToggle(val: string) {
        this.$emit('expandedToggle', val);
    }

    handlePagination(params: PaginationParamsType) {
        this.$emit('pagination', params);
    }

    get noOrders(): boolean {
        return this.orders.length === 0;
    }

    get displayLoadMore(): boolean {
        const { total, limit } = this;
        return total > limit;
    }
}
