
import { EventShippingAddressType } from '@/api/graphQL/graphNodes/types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import PropertyInfoDisplay from '@/projectComponents/propertyInfoDisplay/index.vue';

@Component({
    components: {
        PropertyInfoDisplay,
    },
})
export default class EventShippingAddressDetails extends Vue {
    @Prop({ required: true }) private data!: EventShippingAddressType ;

    get address(): string {
        const {
            address, secondAddress, postcode,
            city, county, country,
        } = this.data;

        let res = address;

        if (!!secondAddress && secondAddress !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${secondAddress}`;
        }

        if (!!city && city !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${city}`;
        }

        if (!!postcode && postcode !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${postcode}`;
        }

        if (!!county && county !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${county}`;
        }

        if (!!country && country !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${country}`;
        }

        return res;
    }
}
