
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';

enum ButtonTypeEnum {
    'contained',
    'text',
    'outlined'
}

@Component
export default class IconButton extends Vue {
    @Prop({ default: '' }) private icon!: string;

    @Prop({ default: 'medium' }) private size!: string;

    @Prop({ default: 'medium' }) private iconSize!: string;

    @Prop({
        default: ButtonTypeEnum[0],
        validator: (val: string) => ButtonTypeEnum[val] !== undefined,
    }) private type!: ButtonTypeEnum;

    @Prop({ default: '' }) private svgSrc!: string;

    @Prop({ default: false }) private disabled!: boolean;

    @Prop({ default: false }) private invertedColors!: boolean;

    @Prop({ required: false }) private ariaLabel!: string;

    @Prop({ default: true }) private ariaHidden!: boolean;

    @Prop({ default: false }) private lightTheme!: boolean;

    get elementClasses() {
        return [
            'IconButton',
            { 'IconButton--lightTheme': this.lightTheme },
            this.disabled ? 'IconButton--disabled' : 'IconRipple',
            { 'IconButton--inverted': this.invertedColors },
            this.type ? `IconButton--${this.type}` : '',
            this.size ? `IconButton--${this.size}` : '',
            this.iconSize ? `IconButton--${this.iconSize}Icon` : '',
        ];
    }

    handleClick() {
        this.$emit('click');
    }
}
