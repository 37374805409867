

import { Component, Vue } from 'vue-property-decorator';
import TypeFilter from './TypeFilters/index.vue';

@Component({
    components: {
        TypeFilter,
    },
})
export default class FilterContainer extends Vue {

}
