const bankDetails = `type BankDetails {
    bankAccountName: String,
    bankAccountNumber: String,
    bankSortCode: String,
}`;

const bankDetailsElementInput = `input BankDetailsElementInput {
    bankAccountName: String,
    bankAccountNumber: String,
    bankSortCode: String,
}`;

const bankDetailsInput = `input BankDetailsInput {
    bankDetails: BankDetailsElementInput,
}`;

const bankDetailsQuery = 'bankDetails: BankDetails';

const bankDetailsMutations = 'updateBankDetails (bankDetails: BankDetailsInput): BankDetails';

export {
    bankDetails, bankDetailsInput, bankDetailsElementInput, bankDetailsQuery, bankDetailsMutations,
};
