
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';

@Component
export default class ProgressBar2 extends Vue {
    @Prop({ default: 0 }) private value!: number;

    @Prop({
        default: ProgressBar2.PROGRESS_BAR_TYPE.horizontal,
        validator: (val: string) => ProgressBar2.PROGRESS_BAR_TYPE[val] !== undefined,
    }) private type!: string;

    static readonly PROGRESS_BAR_TYPE = {
        horizontal: 'horizontal',
        vertical: 'vertical',
    }

    get displayValue() {
        const res = this.value > 100 ? 100 : this.value;
        return `${Math.floor(res)}`;
    }

    get elementClasses() {
        return [
            'ProgressBar2__Background  ProgressBar2__Container',
            `ProgressBar2--${this.type}`,
        ];
    }

    get styleObject() {
        if (this.type === ProgressBar2.PROGRESS_BAR_TYPE.horizontal) {
            return {
                width: `${this.value}%`,
            };
        }
        return { height: `${this.value}%` };
    }
}
