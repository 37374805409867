import { CountriesType } from '@/modules/Event/types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetRegionsQuery extends AbstractQueryResource<GetRegionsResultType> {
    protected getQuery(): string {
        return `query ${this.getName()} {
            regions {
                shortLabel,
                longLabel,
            }
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getRegions';
    }
}

export type GetRegionsResultType = {
    regions: CountriesType[];
}
