import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { LeaderLegsDisplayItemType } from './types';

@Module({
    namespaced: true, dynamic: true, store, name: 'leaderLegsRenderingList',
})
@AutoMutations
export class LeaderLegsRenderingList extends VuexModule {
    private legs: LeaderLegsDisplayItemType[] = [];

    private searchedLegs: LeaderLegsDisplayItemType[] = [];

    private expandedLegs: string[] = [];

    @Mutation
    public setData(val: LeaderLegsDisplayItemType[]) {
        this.legs = [...val];
    }

    @Mutation
    public addData(val: LeaderLegsDisplayItemType[]) {
        const firstItem: LeaderLegsDisplayItemType = val[0];
        if (firstItem) {
            if (firstItem.level === 0) {
                this.legs = [...this.legs, ...val];
            } else {
                const index = this.legs.findIndex((item) => item.id === firstItem.parentId);
                const res = [...this.legs];
                val.reverse().forEach((item) => {
                    const exists = this.legs.find((el) => el.id === item.id);

                    if (!exists) {
                        res.splice(index + 1, 0, item);
                    }
                });
                this.legs = [...res];
            }
        }
    }

    @Mutation
    public removeData(val: LeaderLegsDisplayItemType[]) {
        const res = this.legs;
        val.forEach((item) => {
            const index = res.findIndex((resItem) => resItem.id === item.id);
            res.splice(index, 1);
        });

        this.legs = [...res];
    }

    @Mutation
    setSearchedLegs(val: LeaderLegsDisplayItemType[]) {
        this.searchedLegs = [...val];
    }

    @Mutation
    addSearchedLegs(val: LeaderLegsDisplayItemType[]) {
        const firstItem: LeaderLegsDisplayItemType = val[0];
        if (firstItem) {
            if (firstItem.level === 0) {
                this.searchedLegs = [...this.searchedLegs, ...val];
            } else {
                const index = this.searchedLegs.findIndex((item) => item.id === firstItem.parentId);
                const res = [...this.searchedLegs];
                val.reverse().forEach((item) => {
                    const exists = this.searchedLegs.find((el) => el.id === item.id);

                    if (!exists) {
                        res.splice(index + 1, 0, item);
                    }
                });
                this.searchedLegs = [...res];
            }
        }
    }

    @Mutation
    public removeSearchedLegs(val: LeaderLegsDisplayItemType[]) {
        const res = this.searchedLegs;
        val.forEach((item) => {
            const index = res.findIndex((resItem) => resItem.id === item.id);
            res.splice(index, 1);
        });

        this.searchedLegs = [...res];
    }

    @Mutation
    toggleExpandedList(val: string) {
        if (this.expandedLegs.find((item) => item === val)) {
            const index = this.expandedLegs.findIndex((item) => item === val);
            this.expandedLegs.splice(index, 1);
        } else {
            this.expandedLegs.push(val);
        }
    }

    @Mutation
    clearExpandedList() {
        this.expandedLegs = [];
    }
}

export default getModule(LeaderLegsRenderingList);
