import Vuex, { Module } from 'vuex';
import env from '@/environment';
import LocalStorageManager from '@/utils/local-storage-manager';
import { Impersonator, RequestCache } from '@plumtreesystems/utils';

export default class Store<S> extends Vuex.Store<S> {
    registerModule<T>(path: string|string[], module: Module<T, S>, options: any = {}): void {
        // Check if serialization is turned on and module should be serialized
        if (env.VUE_APP_STORE_SERIALIZATION === 'true' && !LocalStorageManager.moduleExcluded(path)) {
            this.checkStoreVersions();

            let modulePath = '';

            // Get module path as string. Expected that module has one path.
            if (path instanceof Array) {
                const [pathItem] = path;
                modulePath = pathItem;
            } else {
                modulePath = path;
            }

            // check if module state is up to date.
            if (LocalStorageManager.shouldStoreModuleUpdate(modulePath, module.state)) {
                // Update store version
                LocalStorageManager.setModuleStoreVersion(
                    modulePath,
                    LocalStorageManager.calculateStoreVersion(module.state),
                );

                const storeState = LocalStorageManager.getResolvedStoreState()
                    ? LocalStorageManager.getResolvedStoreState() : {};
                this.saveDataToCache(modulePath, module.state, storeState);

                this.saveStateToImpersonateStore(modulePath, module.state);

                // Set default data module structure for impersonation
                this.saveModuleDefaultImpersonateData(modulePath, module.state);

                super.registerModule(modulePath, module, options);
            } else {
                // Saved data string.
                const storeState = LocalStorageManager.getResolvedStoreState() || {};

                let payload = null;

                // Module state from store state.
                const moduleData = storeState[modulePath];

                if (moduleData) {
                    // If saved data exists set module data to payload.
                    // Further this data will be set as module state.
                    payload = moduleData;
                    this.saveDataToCache(modulePath, moduleData, storeState);
                } else {
                    // Get module state data. If state is undefined set as empty object.
                    const moduleState = module.state ? module.state : {};
                    // Set module initial state in store state.
                    // It's important to always have all modules state
                    // in store cache and impersonate cache.
                    // When switching between store cache and impersonate cache,
                    // all cached modules should have their state saved.
                    this.saveDataToCache(modulePath, moduleState, storeState);
                }

                if (payload) {
                    // @ts-ignore
                    // eslint-disable-next-line no-param-reassign
                    module.state = payload;
                } else {
                    this.saveStateToImpersonateStore(modulePath, module.state);
                    // Set default data module structure for impersonation
                    this.saveModuleDefaultImpersonateData(modulePath, module.state);
                }

                // @ts-ignore
                super.registerModule(path, module, options);
            }
        } else {
            // @ts-ignore
            super.registerModule(path, module, options);
        }
    }

    saveStateToImpersonateStore(path: string, state: any) {
        if (!Impersonator.getImpersonateToken()) {
            const localImpersonateState: any = LocalStorageManager
                .getImpersonateStoreState();
            let impersonateState = LocalStorageManager.getDefaultVersionsObject();

            if (localImpersonateState) {
                impersonateState = { ...localImpersonateState };
            }

            impersonateState[path] = { ...state };
            LocalStorageManager
                .setImpersonateStoreState(impersonateState);

            LocalStorageManager.setImpersonateModuleStoreVersion(
                path,
                LocalStorageManager.calculateStoreVersion(state),
            );
        }
    }

    saveDataToCache(path: string, state: any, storeState: any) {
        // eslint-disable-next-line no-param-reassign
        storeState[path] = { ...state };
        LocalStorageManager.setStoreState(storeState);
    }

    saveModuleDefaultImpersonateData(path: string, state: any) {
        let impersonateDefaultState = LocalStorageManager.getStoreDefaultState();

        if (!impersonateDefaultState) {
            impersonateDefaultState = {};
        }
        impersonateDefaultState[path] = state;

        LocalStorageManager.setStoreDefaultState(impersonateDefaultState);
    }

    checkStoreVersions() {
        if (LocalStorageManager.shouldStoreUpdate()) {
            LocalStorageManager.clearStoreDeprecatedData();
            RequestCache.clearRequestCache();
        }

        if (LocalStorageManager.shouldImpersonateStoreUpdate()) {
            LocalStorageManager.clearImpersonateDeprecatedData();
            RequestCache.clearImpersonateCache();
        }
    }
}
