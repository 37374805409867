
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';

@Component
export default class SkeletonList extends Vue {
    @Prop({ default: false }) private withoutPadding!: boolean;

    @Prop({ default: false }) private paddingTablet!: boolean;

    get elementClasses() {
        return [
            'pts-layout',
            'pts-gutter',
            'SkeletonList__Container',
            this.withoutPadding ? 'SkeletonList__Container--withoutPadding' : '',
            this.paddingTablet ? 'SkeletonList__Container--paddingTablet' : '',
        ];
    }
}
