import { RankType } from '@/api/graphQL/graphNodes/types';

const rank: () => RankType = () => ({
    id: '-1',
    requirements: [],
    titleRequirements: [],
    label: '',
    power: -1,
});

export { rank };
