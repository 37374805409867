import { IncomeSummaryType } from '@/api/graphQL/graphNodes/types';
import dateManager from '@/utils/time';
import mockedDateManager from '@/utils/mocked-date-manager';
import env from '@/environment';

const defaultData: () => IncomeSummaryType = () => ({
    gv: 0,
    ov: 0,
    pv: 0,
    total: 0,
    title: {
        label: '',
    },
    bonusTotals: [],
});

const defaultCurrentYear: () => string = () => (
    env.VUE_APP_MOCK_GRAPHQL === 'true'
        ? mockedDateManager.getCurrentDate('YYYY')
        : dateManager.getCurrentDate('YYYY')
);

export { defaultData, defaultCurrentYear };
