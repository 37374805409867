import { RankType } from '@/api/graphQL/graphNodes/types';
import { LegType } from '@/modules/types';
import resolveStatusType from '@/projectComponents/enrolleeLegItem/Item/services/resolveStatusType';

const calculateStatus: (data: LegType, ranks: RankType[], resType: string)
 => string = (data, ranks, resType) => {
     const rank = ranks.find((item) => item.label === 'temporary.rank.teamLeader');

     let percentage = 0;

     if (rank && rank.requirements) {
         let total = 0;
         let count = 0;

         rank.requirements.forEach((item) => {
             const stat = data.stats.find((statItem) => statItem.metric === item.metric);
             if (stat) {
                 count += 1;

                 const x = Math.floor(Number(stat.value) * 100) / Number(item.value);
                 total += x > 100 ? 100 : x;
             }
         });

         percentage = total / count;
     } else {
         percentage = 100;
     }

     if (resType === 'status') {
         return resolveStatusType(percentage);
     }

     return String(percentage);
 };

export default calculateStatus;
