const profileInput = `input ProfileInput {
    firstName: String,
    lastName: String,
    phoneNumber: String,
    email: String,
    address: String,
    secondAddress: String,
    birthDate: String,
    postCode: String,
    town: String,
    county: String,
    country: String,
    bankDetails: BankDetailsInput,
    businessDetails: BusinessDetailsInput,
}`;

const customerInput = `input DownlineProfileInput {
    firstName: String,
    lastName: String,
    phoneNumber: String,
    email: String,
    address: String,
    secondAddress: String,
    birthDate: String,
    postCode: String,
    town: String,
    county: String,
    region: String,
    hidden: Boolean,
}`;

const profilePictureInput = `input Upload {
    link: String
}`;

const profileQuery = 'profile: Enrollee';

const profileMutations = `
    updateProfile (profile: ProfileInput): Enrollee
    updateDownlineProfile (enrolleeId: String!, profile: DownlineProfileInput): Enrollee
    uploadProfilePicture (uploadedFile: Upload): Asset!
`;

export {
    profileInput, profileQuery, profileMutations,
    profilePictureInput, customerInput,
};
