// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class UpdateBankDetailsQuery
    extends AbstractQueryResource<UpdateBankDetailsResultType> {
    protected getQuery(): string {
        return `mutation ${this.getName()}($bankDetails: BankDetailsInput) {
            updateBankDetails(bankDetails: $bankDetails) {
                bankAccountName,
                bankAccountNumber,
                bankSortCode,
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'updateProfile';
    }
}

export type UpdateBankDetailsResultType = {
    bankAccountName: string|null;
    bankAccountNumber: string|null;
    bankSortCode: string|null;
}
