
import {
    Vue, Component, Prop,
} from 'vue-property-decorator';

@Component
export default class AgreedToReceiveEmail extends Vue {
    @Prop({ required: true }) private value!: boolean;

    handleValue(value: boolean) {
        this.$emit('input', value);
    }
}
