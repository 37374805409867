const commissionBonus = `type CommissionBonus {
    key: String!,
    label: String!,
    amount: String!,
}`;

const income = `type Income {
    centralTeam: Float!
    leadership1: Float!
    leadership2: Float!
    leadership3: Float!
    leadership4: Float!
    additional: Float!
    productivity: Float!
    adjustments: Float!
    bonusTotals: [CommissionBonus]

    total: Float!
    pv: Float!
    gv: Float!
    ov: Float!
    title: Rank!
}`;

export {
    income, commissionBonus,
};
