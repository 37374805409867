import { StatsType } from '@/api/graphQL/graphNodes/types';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import { BALANCE_CURRENCY, TRANSACTION_CHANGE_TYPE } from '@/modules/StoreCredit/constants';
import mockedDateManager from '@/utils/mocked-date-manager';
import dateManager from '@/utils/time';
import { ResolverTransactionsDataType, ResolverTransactionType } from '../types';
import { enrollee } from './enrollee';

const currentDate = mockedDateManager.getCurrentDate(dateManager.getDayTimeFormat());

const transactionsData: ResolverTransactionsDataType[] = [
    {
        enrolleeId: '0',
        date: dateManager.getDateWithOffset(-2, currentDate),
        description: '',
        refId: '-',
        amount: 5.59,
        currency: BALANCE_CURRENCY.gbp,
        type: TRANSACTION_CHANGE_TYPE.deposit,
    },
    {
        enrolleeId: '0',
        date: dateManager.getDateWithOffset(-5, currentDate),
        description: '',
        refId: '-',
        amount: 4.18,
        currency: BALANCE_CURRENCY.gbp,
        type: TRANSACTION_CHANGE_TYPE.charge,
    },
    {
        enrolleeId: '0',
        date: dateManager.getDateWithOffset(-3, currentDate),
        description: '',
        refId: '-',
        amount: 39.85,
        currency: BALANCE_CURRENCY.gbp,
        type: TRANSACTION_CHANGE_TYPE.deposit,
    },
    {
        enrolleeId: '0',
        date: dateManager.getDateWithOffset(0, currentDate),
        description: '',
        refId: '-',
        amount: 15.63,
        currency: BALANCE_CURRENCY.gbp,
        type: TRANSACTION_CHANGE_TYPE.deposit,
    },
    {
        enrolleeId: '0',
        date: dateManager.getDateWithOffset(0, currentDate),
        description: '',
        refId: '-',
        amount: 4.82,
        currency: BALANCE_CURRENCY.gbp,
        type: TRANSACTION_CHANGE_TYPE.charge,
    },
    {
        enrolleeId: '0',
        date: dateManager.getDateWithOffset(-1, currentDate),
        description: '',
        refId: '-',
        amount: 3.21,
        currency: BALANCE_CURRENCY.gbp,
        type: TRANSACTION_CHANGE_TYPE.deposit,
    },
    {
        enrolleeId: '0',
        date: dateManager.getDateWithOffset(-9, currentDate),
        description: '',
        refId: '-',
        amount: 42.12,
        currency: BALANCE_CURRENCY.gbp,
        type: TRANSACTION_CHANGE_TYPE.charge,
    },
    {
        enrolleeId: '0',
        date: dateManager.getDateWithOffset(-6, currentDate),
        description: '',
        refId: '-',
        amount: 23.65,
        currency: BALANCE_CURRENCY.gbp,
        type: TRANSACTION_CHANGE_TYPE.deposit,
    },
    {
        enrolleeId: '0',
        date: dateManager.getDateWithOffset(0, currentDate),
        description: '',
        refId: '-',
        amount: 17.10,
        currency: BALANCE_CURRENCY.gbp,
        type: TRANSACTION_CHANGE_TYPE.deposit,
    },
    {
        enrolleeId: '0',
        date: dateManager.getDateWithOffset(-3, currentDate),
        description: '',
        refId: '-',
        amount: 5.98,
        currency: BALANCE_CURRENCY.gbp,
        type: TRANSACTION_CHANGE_TYPE.deposit,
    },
    {
        enrolleeId: '0',
        date: dateManager.getDateWithOffset(-7, currentDate),
        description: '',
        refId: '-',
        amount: 14.31,
        currency: BALANCE_CURRENCY.gbp,
        type: TRANSACTION_CHANGE_TYPE.charge,
    },
    {
        enrolleeId: '0',
        date: dateManager.getDateWithOffset(-8, currentDate),
        description: '',
        refId: '-',
        amount: 18.15,
        currency: BALANCE_CURRENCY.gbp,
        type: TRANSACTION_CHANGE_TYPE.charge,
    },
];

const transactions: (enrolleeId: string)
=> ResolverTransactionType[] = (enrolleeId) => transactionsData
    .filter((item) => item.enrolleeId === enrolleeId)
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

const balance: (enrolleeId: string)
=> StatsType = (enrolleeId) => {
    const currency = enrollee(enrolleeId).country === COUNTRY_SELECT_OPTIONS.gb
        ? BALANCE_CURRENCY.gbp
        : BALANCE_CURRENCY.eur;

    const totalBalance = transactions(enrolleeId)
        .filter((item) => item.currency === currency)
        .reduce((total, transaction) => {
            if (transaction.type === TRANSACTION_CHANGE_TYPE.deposit) {
                return total + transaction.amount;
            }
            return total - transaction.amount;
        }, 0);

    return {
        metric: currency,
        value: String(totalBalance),
    };
};

export { transactions, balance };
