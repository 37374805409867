
import {
    Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import profile from '@/modules/Profile';
import Camera from '@/projectComponents/camera/index.vue';
import profilePicture from '@/modules/Profile/profilePicture';

@Component({
    components: {
        Camera,
    },
})
export default class ProfileCamera extends Vue {
    @Get(profile) private cameraDialogOpen!: boolean;

    @Get(profilePicture, 'loading') private uploadLoading!: boolean;

    @Get(profilePicture) private isCameraLoading!: boolean;

    @Get(profilePicture) private cameraNotPermitted!: boolean;

    @Get(profilePicture) private isPhotoTaken!: boolean;

    @Get(profilePicture) private displayWidth!: number;

    @Get(profilePicture) private displayHeight!: number;

    @Get(profilePicture) private cameraWidth!: number;

    @Get(profilePicture) private cameraHeight!: number;

    @Prop() private loading!: boolean;

    @Prop() private image!: string;

    async handleCameraUpload() {
        // @ts-ignore
        const croppedImg = this.$refs.camera.getCroppedImg();
        await profilePicture.uploadProfilePicture({ img: croppedImg });
        this.handleCameraModalClose();
    }

    handleCameraModalClose() {
        profile.setCameraDialogOpen(false);
    }

    takePhoto() {
        if (!this.isPhotoTaken) {
            profilePicture.setIsShotPhoto(true);

            const FLASH_TIMEOUT = 50;

            setTimeout(() => {
                profilePicture.setIsShotPhoto(false);
            }, FLASH_TIMEOUT);
        }
        profilePicture.setIsPhotoTaken(!this.isPhotoTaken);

        // @ts-ignore
        const getPhoto = this.$refs.camera.getPhotoRef(this.isPhotoTaken);
        profilePicture.setImage(getPhoto);
    }

    setDimensions(val: {width: number, height: number}) {
        profilePicture.setDisplayWidth(val.width);
        profilePicture.setDisplayHeight(val.height);
    }

    @Watch('cameraDialogOpen')
    cameraDialogOpenChange() {
        if (this.cameraDialogOpen) {
            this.startCamera();
        }
    }

    async startCamera() {
        await profilePicture.clearImageData();
        this.createCameraElement();
    }

    createCameraElement() {
        profilePicture.setIsCameraLoading(true);
        profilePicture.setCameraNotPermitted(false);

        const params = {
            audio: false,
            video: true,
        };

        // @ts-ignore
        const constraints = params;

        // @ts-ignore
        window.constraints = params;
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
                const streamSettings = stream.getVideoTracks()[0].getSettings();
                const { width, height } = streamSettings;

                if (width && height) {
                    profilePicture.setCameraWidth(width);
                    profilePicture.setCameraHeight(height);

                    // @ts-ignore
                    this.$refs.camera.setFittingResolution(width, height);
                }

                profilePicture.setIsCameraLoading(false);
                // @ts-ignore
                this.$refs.camera.$refs.camera.srcObject = stream;
            })
            .catch(() => {
                profilePicture.setCameraNotPermitted(true);
            })
            .finally(() => {
                profilePicture.setIsCameraLoading(false);
            });
    }
}
