import API from '@/api/graphQL';
import { GENEALOGY_ENROLLEE_PARENT_TYPES, GetGenealogyEnrolleeParamsType } from '@/api/graphQL/graphNodes/GetGenealogyEnrolleeQuery';
import { GetRisingStarsByIdParamsType } from '@/api/graphQL/graphNodes/GetRisingStarsByIdQuery';
import { RisingStarsParamsType, RisingStarsSearchParamsType } from '@/api/graphQL/graphNodes/GetRisingStarsQuery';

export default class RisingStarsRepository {
    static getRisingStars(params: RisingStarsParamsType) {
        return API
            .getRisingStars()
            .query(params, {}, { type: '' });
    }

    static searchRisingStars(params: RisingStarsSearchParamsType) {
        return API
            .getRisingStars()
            .query(params, {}, { type: 'search' });
    }

    static getRisingStarsById(params: GetRisingStarsByIdParamsType) {
        return API
            .getRisingStarsById()
            .query(params);
    }

    static getLeg(params: GetGenealogyEnrolleeParamsType) {
        return API
            .getGenealogyEnrollee()
            .query(params, {}, {
                withParent: true,
                parentType: GENEALOGY_ENROLLEE_PARENT_TYPES.identity,
            });
    }
}
