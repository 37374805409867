import { ResolverLeaderboardListItem } from '../types';

const leaderboardList: ResolverLeaderboardListItem[] = [
    {
        awardId: '0',
        position: 6,
        previousPosition: 6,
        enrolleeId: '0',
        name: 'Sarah Hill',
        metricValue: '400',
    },
    {
        awardId: '1',
        position: 7,
        previousPosition: 8,
        enrolleeId: '0',
        name: 'Sarah Hill',
        metricValue: '370',
    },
    {
        awardId: '2',
        position: 8,
        previousPosition: 2,
        enrolleeId: '0',
        name: 'Sarah Hill',
        metricValue: '351',
    },
];

const leaderboardItems = (awardId: string): ResolverLeaderboardListItem[] => {
    const item = leaderboardList.find((boardItem) => boardItem.awardId === awardId);
    return item ? [item] : [];
};

export { leaderboardItems };
