
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PaginationParamsType } from './types';

@Component
export default class Pagination extends Vue {
    @Prop({ required: true }) private offset!: number;

    @Prop({ required: true }) private total!: number;

    @Prop({ default: 20 }) private limit!: number;

    @Prop({ default: false }) private disabled!: boolean;

    @Prop({ default: false }) private lightTheme!: boolean;

    get lastPage(): number {
        let res: number = this.total / this.limit;
        if (this.total % this.limit) {
            res = Math.floor(res);
        } else {
            res -= 1;
        }
        return res;
    }

    get lastPageDisplayVal() {
        return this.lastPage + 1;
    }

    get isPrevDisabled(): boolean {
        return this.offset === 0 || this.disabled;
    }

    get isNextDisabled(): boolean {
        return this.offset + this.limit >= this.total || this.disabled;
    }

    get page(): number {
        let res: number = this.offset / this.limit;
        res += 1;

        return res;
    }

    get containerClasses() {
        return [
            'Pagination__Container',
            { 'Pagination__Container--lightTheme': this.lightTheme },
        ];
    }

    onFirst() {
        const params: PaginationParamsType = {
            offset: 0,
            limit: this.limit,
        };
        this.$emit('onPageChange', params);
    }

    onNext() {
        const params: PaginationParamsType = {
            limit: this.limit,
            offset: this.offset + this.limit,
        };
        this.$emit('onPageChange', params);
    }

    onPrev() {
        const params: PaginationParamsType = {
            limit: this.limit,
            offset: this.offset - this.limit,
        };
        this.$emit('onPageChange', params);
    }

    onLast() {
        const params: PaginationParamsType = {
            limit: this.limit,
            offset: this.limit * this.lastPage,
        };
        this.$emit('onPageChange', params);
    }
}
