
import { Vue, Component } from 'vue-property-decorator';
import { scrollToTop } from '@plumtreesystems/utils';
import { Get } from '@/utils/vuex-module-mutators';
import componentsControls from '@/modules/ComponentsControls';

@Component
export default class ScrollButton extends Vue {
    @Get(componentsControls) private drawerOpened!: boolean;

    mounted() {
        this.handleScroll();
    }

    handleScroll() {
        const el = document.getElementById('app') as HTMLElement;
        el.addEventListener('scroll', () => {
            const nav = document.querySelector('nav.Drawer__Navigation') as HTMLElement;
            const statusbar = document.querySelector('.StatusBar__Content') as HTMLElement;
            const scroller = document.querySelector('button.ScrollButton') as HTMLElement;
            const drawer = document.querySelector('button.DrawerFab') as HTMLElement;

            if (nav && statusbar) {
                if (el.scrollTop > (nav.clientHeight + statusbar.clientHeight)) {
                    scroller.classList.add('ScrollButton--enabled');
                    drawer.classList.add('DrawerFab--moved');
                } else {
                    scroller.classList.remove('ScrollButton--enabled');
                    drawer.classList.remove('DrawerFab--moved');
                }
            }
        });
    }

    handleClick() {
        scrollToTop('#app');
    }

    get scrollClasses() {
        return [
            'ScrollButton Ripple',
            'md-elevation-8',
            this.drawerOpened ? 'ScrollButton--disabled' : '',
        ];
    }
}
