import API from '@/api/rest';
import { PublicInviteAmbassadorParamsType } from '@/api/graphQL/graphNodes/PublicInviteAmbassadorQuery';

export default class PublicRepository {
    static publicInviteAmbassador(data: PublicInviteAmbassadorParamsType, vanityUrl: string) {
        return API
            .sendPublicInvitation()
            .sendInvitation(vanityUrl, data);
    }

    static publicResendInvite(data) {
        return API
            .resendInvitation()
            .send(data);
    }
}
