const termsAndConditions = `type TermsAndConditions {
    id: String,
    createdAt: String!,
    content: Asset,
    type: String!
}`;

const termsAndConditionsQuery = `
    termsAndConditions (type: String): TermsAndConditions
`;

export {
    termsAndConditionsQuery, termsAndConditions,
};
