import { OrderType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetCustomerOrderDetails extends
    AbstractQueryResource<GetCustomerOrderDetailsResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($id: String!, $orderId: String!) {
            enrollee(id: $id) {
                order (orderId: $orderId) {
                    id,
                    value,
                    grossValue,
                    commissionableValue,
                    psc,
                    date,
                    shippingCost,
                    discount,
                    products {
                        id,
                        price,
                        description,
                        quantity,
                        total,
                        discounts,
                        cv,
                    },
                    enrollee {
                        id,
                        firstName,
                        lastName,
                    },
                    customer {
                        id,
                        firstName,
                        lastName,
                    },
                },
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getMyCustomerOrder';
    }
}

export type GetCustomerOrderDetailsResultType = {
    enrollee: {
        order: OrderType;
    };
}

export type GetCustomerOrderDetailsParamsType = {
    id: string;
    orderId: string;
}
