import { EnrolleeCustomerOrderType } from '@/api/graphQL/graphNodes/types';

const initialEnrolleeOrderData: () => EnrolleeCustomerOrderType = () => ({
    id: '',
    firstName: '',
    lastName: '',
    searchOrders: [],
    searchOrdersCount: 0,
});

export {
    initialEnrolleeOrderData,
};
