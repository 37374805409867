import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { RankType } from '@/api/graphQL/graphNodes/types';
import { GetRanksResultType } from '@/api/graphQL/graphNodes/GetRanksQuery';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import rankRepository from './services/RankRepository';

@Module({
    namespaced: true, dynamic: true, store, name: 'rank',
})
@AutoMutations
export class Rank extends VuexModule {
    private ranks: RankType[] = [];

    private loaded: boolean = false;

    private loading: boolean = false;

    get spaPower(): number {
        return 30;
    }

    get allRanks(): RankType[] {
        return this.ranks;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setLoaded(val: boolean) {
        this.loaded = val;
    }

    @Mutation
    public setRanks(val: RankType[]) {
        this.ranks = [...val.sort((a, b) => a.power - b.power)];
    }

    @Action()
    public async getRanks() {
        try {
            this.setLoading(true);
            const res: GetRanksResultType = await rankRepository.getRanks();
            this.setRanks(res.ranks);

            this.setLoaded(true);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(Rank);
