import API from '@/api/graphQL';
import { GetCustomerOrdersParamsType } from '@/api/graphQL/graphNodes/GetCustomerOrdersQuery';
import { NewStartersParamsType, NewStartersSearchParamsType } from '@/api/graphQL/graphNodes/GetNewStartersQuery';

export default class NewStartersRepository {
    static getNewStarters(params: NewStartersParamsType) {
        return API
            .getNewStarters()
            .query(params, {}, { type: '' });
    }

    static searchNewStarters(params: NewStartersSearchParamsType) {
        return API
            .getNewStarters()
            .query(params, {}, { type: 'search' });
    }

    static getOrders(data: GetCustomerOrdersParamsType) {
        return API
            .getCustomerOrders()
            .query(data);
    }
}
