import { ResolverReportType } from '../types';

const reportList: ResolverReportType[] = [];

const reports: (runId: string|null) => ResolverReportType[] = (runId) => reportList
    .filter((item) => {
        if (runId === null) {
            return item.runId === '2';
        }

        return item.runId === runId;
    });

export { reports };
