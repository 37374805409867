
import {
    Component, Vue, Prop,
} from 'vue-property-decorator';
import { RouteType } from '@/views/Navigation/Item/types';

@Component
export default class BackButton extends Vue {
    @Prop() private backRoute!: RouteType;

    handleBackButton() {
        this.$emit('onBackButton');
        this.handleClose();
    }

    handleClose() {
        this.$emit('onClose');
        if (!this.backRoute) {
            this.$router.go(-1);
        } else {
            this.$router.push(this.backRoute);
        }
    }
}
