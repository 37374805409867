// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { AchievementAwardType } from './types';

export default class GetRankingsQuery extends
    AbstractQueryResource<GetRankingsResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($type: String!, $skip: Int, $limit: Int, $query: String) {
            achievementAwards(type: $type, limit: $limit, skip: $skip, query: $query) {
                type,
                title,
                tooltip,
                description,
                leaderboard {
                    position,
                    previousPosition,
                    enrolleeId,
                    name
                    metricValue,
                }
            },
            achievementAwardsCount(type: $type, query: $query)
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getRankings';
    }
}

export type GetRankingsResultType = {
    achievementAwards: AchievementAwardType;
    achievementAwardsCount: number;
}

export type GetRankingsParamsType = {
    limit: number;
    skip: number;
    type: string;
    query: string;
}
