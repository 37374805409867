// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { HostessRegistrationType, TermsAndConditionsType } from './types';

export default class GetHostessRegistrationQuery
    extends AbstractQueryResource<GetHostessRegistrationResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($type: String!) {
            profile {
                firstName,
                lastName,
                email,
                address,
                phoneNumber,
                shadow
            },
            termsAndConditions(type: $type) {
                id,
                createdAt,
                content {
                    id,
                    category,
                    link,
                    fileName,
                    context {
                        metric,
                        value,
                    },
                    title,
                    originalFileName,
                    uploadDate,
                }
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getHostessRegistration';
    }
}

export type GetHostessRegistrationResultType = {
    profile: HostessRegistrationType;
    termsAndConditions: TermsAndConditionsType;
}

export type GetHostessRegistrationParamsType = {
    type: string;
}
