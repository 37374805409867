const hostessReward = `type HostessReward {
    label: String!
    thresholdEUR: Int!
    thresholdGBP: Int!
    rewards: [String]!
}`;

const hostessRewardQuery = `
hostessRewards: [HostessReward]
`;

export { hostessReward, hostessRewardQuery };
