import {
    Module, VuexModule, Mutation, getModule,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { GetEventsResultType, GetEventsParamsType, GetEventsResultDataType } from '@/api/graphQL/graphNodes/GetEventsQuery';
import dateManager from '@/utils/time';
import { EventType } from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import {
    SetCalendarDayObject, CalendarObject, CalendarDisplayObject, CalendarDisplayDayObject,
} from './types';
import EventRepository from '../services/eventRepository';
import { calendarDayConfig } from '../configs';
import { CALENDAR_TIME_FRAME_OPTIONS } from '../constants';

@Module({
    namespaced: true, dynamic: true, store, name: 'calendarEvents',
})
@AutoMutations
class CalendarEvents extends VuexModule {
    private isDataLoading: boolean = false;

    private days: CalendarObject = {};

    get displayEvents(): CalendarDisplayObject {
        const res = {};
        // iterates through object where key is day and value is day object
        Object.entries(this.days).forEach((day) => {
            const previousDayId = dateManager
                .getDateWithOffset(-1, day[0], dateManager.getDateFormat());
            const previousDay: CalendarDisplayDayObject = res[previousDayId];
            // shows if day have more events than maximum amount to display
            const overfull = day[1].events.length > calendarDayConfig.maxPills;

            // created array to display events
            const pills: (EventType|null)[] = [];
            // added empty spaces for events in array.
            for (let i = 0; i < calendarDayConfig.maxPills; i++) {
                pills.push(null);
            }

            if (!previousDay) {
                if (!overfull) {
                    // without previous day pills are filled from first.
                    day[1].events.forEach((event, index) => {
                        pills[index] = event;
                    });
                } else {
                    // if day have more events than allowed to display,
                    // available spaces are filled with first events that day
                    for (let i = 0; i < calendarDayConfig.maxPills; i++) {
                        pills[i] = day[1].events[i];
                    }
                }
            } else {
                let size: number = 0;
                // determined amount of events to display
                if (calendarDayConfig.maxPills > day[1].events.length) {
                    size = day[1].events.length;
                } else {
                    size = calendarDayConfig.maxPills;
                }

                // iterate through all events to display
                for (let i = 0; i < size; i++) {
                    const event: EventType = day[1].events[i];
                    let previousIndex: (null|number) = null;

                    // find if event were displayed in pill on previous day
                    previousDay.pills.find((pill, index) => {
                        if (pill && pill.id === event.id) {
                            // set previous day pill index to show event constantly.
                            previousIndex = index;
                            return true;
                        }
                        return false;
                    });

                    if (previousIndex !== null) {
                        // set event to same pill as in previous day.
                        pills[previousIndex] = event;
                    } else {
                        let place: null|number = null;
                        // find free space for new event pill
                        pills.find((p, index) => {
                            if (p === null) {
                                place = index;
                                return true;
                            }
                            return false;
                        });

                        if (place !== null) {
                            // set event to pill
                            pills[place] = event;
                        }
                    }
                }
            }

            res[day[0]] = {
                pills,
                overfull,
            };
        });

        return res;
    }

    get getDays(): CalendarObject {
        return this.days;
    }

    @Mutation
    setDataLoading(state: boolean) {
        this.isDataLoading = state;
    }

    @Mutation
    setDays(data: SetCalendarDayObject) {
        const { key, val } = data;

        const res = { ...this.days };
        res[key] = val;

        this.days = res;
    }

    @Mutation
    addDays(data: SetCalendarDayObject) {
        const { key, val } = data;

        const res = { ...this.days };
        res[key].events = [...res[key].events, ...val.events];
        res[key].statistics = val.statistics;

        this.days = res;
    }

    @Mutation
    clearDays() {
        this.days = {};
    }

    @Action()
    async loadDayEvents(data: {
        dateFrom: string, dateTo: string, offset: number,
        type: string, status: string, timeframe: string,
    }) {
        try {
            const {
                dateFrom, dateTo, offset, type, status, timeframe,
            } = data;
            const params: GetEventsParamsType = {
                dateFrom,
                dateTo,
                offset,
                type,
                status,
            };

            const isDaily = timeframe === CALENDAR_TIME_FRAME_OPTIONS.day;

            const result: GetEventsResultType = await EventRepository
                .getEvents(params, { type: timeframe });

            this.handleSave({
                res: isDaily ? result.calendarDaily! : result.calendar!,
                offset: data.offset,
            });
        } catch (e) {
            ErrorsProcessor.process(e);
        }
    }

    @Action()
    handleSave(data: {res: GetEventsResultDataType[], offset: number}) {
        const { res, offset } = data;

        if (offset === 0) {
            res
                .forEach((item) => {
                    this.setDays({
                        key: item.day,
                        val: {
                            events: item.events,
                            statistics: {
                                totalEvents: item.totalEvents,
                            },
                        },
                    });
                });
        } else {
            res
                .forEach((item) => {
                    this.addDays({
                        key: item.day,
                        val: {
                            events: item.events,
                            statistics: {
                                totalEvents: item.totalEvents,
                            },
                        },
                    });
                });
        }
    }
}

export default getModule(CalendarEvents);
