import { AbstractResource, Methods } from '../AbstractResource';
import { ResendInviteParamsType } from './types';

export default class ResendInvite extends AbstractResource<string> {
    protected noAuthentication: boolean = true;

    protected getAllowedMethods(): Methods[] {
        return [Methods.POST];
    }

    protected getPath(): string {
        return '/resend-invite-api';
    }

    public send(data: ResendInviteParamsType): Promise<string> {
        return this.post(data);
    }
}
