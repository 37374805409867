import { CALENDAR_TIME_FRAME_OPTIONS } from '@/modules/Calendar/constants';
import { EventType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetEventsQuery extends AbstractQueryResource<GetEventsResultType> {
    protected getQuery(options: { type: string }): string {
        const { type } = options;

        return `query ${this.getName()}($dateFrom: DateTime, $dateTo: DateTime, $limit: Int = 10, $offset: Int = 0, $type: String!, $status: String!) {
            ${type === CALENDAR_TIME_FRAME_OPTIONS.day ? 'calendarDaily' : 'calendar'}(dateFrom: $dateFrom, dateTo: $dateTo, limit: $limit, offset: $offset, type: $type, status: $status) {
                day,
                events {
                    id,
                    title,
                    campaignDateFrom,
                    campaignDateTo,
                    eventDateFrom,
                    eventDateTo,
                    address,
                    type,
                    ambassador {
                        firstName,
                        lastName,
                    },
                    hostess {
                        firstName,
                        lastName,
                    },
                    invitations {
                        enrollee {
                            firstName,
                            lastName,
                        },
                        attending,
                        type
                    },
                    ${type === CALENDAR_TIME_FRAME_OPTIONS.day ? 'eventOrdersCount,' : ''}
                    canceled,
                    closed,
                    ${type === CALENDAR_TIME_FRAME_OPTIONS.day ? 'totalSales' : ''}
                },
                totalEvents
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getEvents';
    }
}

export type GetEventsResultType = {
    calendar?: GetEventsResultDataType[];
    calendarDaily?: GetEventsResultDataType[];
}

export type GetEventsResultDataType = {
    day: string;
    events: GetEventsEventType[];
    totalEvents: number;
}

export type GetEventsEventType = EventType & {
    totalSales?: number;
    eventOrdersCount?: number;
}

export type GetEventsParamsType = {
    dateFrom: string,
    dateTo: string,
    offset?: number;
    limit?: number;
    type: string;
    status: string;
}
