// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { SponsorType } from './types';

export default class GetSponsorQuery extends
    AbstractQueryResource<GetSponsorResultType> {
    protected getQuery(): string {
        return `query ${this.getName()} {
            profile {
                parent {
                    id,
                    firstName,
                    lastName,
                    phoneNumber,
                    email,
                },
                uplineLeader {
                    id,
                    firstName,
                    lastName,
                    phoneNumber,
                    email,
                }
            }
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getSponsor';
    }
}

export type GetSponsorResultType = {
    profile: {
        parent: SponsorType|null;
        uplineLeader: SponsorType|null;
    }
}
