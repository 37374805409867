export const REGISTER_FLAGGED_DOCUMENT_TYPE = {
    passport: 'passport',
    driverLicense: 'driverLicense',
};

export const LEXIS_NEXIS_STATUS = {
    none: null,
    pass: 'PASS',
    refer: 'REFER',
    fail: 'FAIL',
};

export const REGISTRATION_STEPS = [
    {
        label: 'Sign up',
        value: 0,
    },
    {
        label: 'Order your Kit',
        value: 1,
    },
    {
        label: 'You\'re all set!',
        value: 2,
    },
];
